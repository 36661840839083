import React, { useEffect, useState } from 'react';
import {
  StyledAgeContainer,
  StyledAgeOverLay,
  StyledOverlayContainer,
  StyledOverlayHead,
  StyledOverlayBody,
  StyledHead,
  StyledCustomInput,
  StyledCustonNumber,
} from './style';
import { NumberRemovebackIconSvg, PopUpCloseIconSvg } from '@/shared/svg/index';
import { Link } from 'react-router-dom';

interface childProps {
  selectedEventData: any;
  selectedChip: any;
  eventsData: any;
  closeCustomAmountPopup: any;
  customAmount: any;
  betType: any;
}

const CustomAmount = (props: childProps) => {
  const [keyBoardCount, setKeyBoardCount] = useState([]);
  const [customBetAmount, setCustomBetAmount] = useState('');
  const [amountValidation, setAmountValidation] = useState(false);
  //const [divisibleValue, setDivisibleValue] = useState(5);

  const generateKeyBoard = async () => {
    var arrayCount = Array.apply(null, { length: 10 }).map(Number.call, Number);
    setKeyBoardCount(arrayCount);
  };

  const customAmount = async (val: any) => {
    setAmountValidation(false);
    val = customBetAmount + val;
    setCustomBetAmount(val);
  };

  const closeCustomAmountPopup = async () => {
    props.closeCustomAmountPopup();
  };

  const clearAmount = async () => {
    setCustomBetAmount('');
  };

  const confirmcustomAmount = async () => {
    if (customBetAmount == '' || customBetAmount == '0') {
      setAmountValidation(true);
      return;
    }

    if (props.betType == 'single') {
      if (parseFloat(customBetAmount) % 5 === 0) {
        props.customAmount(customBetAmount);
        closeCustomAmountPopup();
      } else {
        setAmountValidation(true);
      }
    } else {
      if (parseFloat(customBetAmount) % 6 === 0) {
        props.customAmount(customBetAmount);
        closeCustomAmountPopup();
      } else {
        setAmountValidation(true);
      }
    }
  };

  useEffect(() => {
    generateKeyBoard();
  }, []);

  return (
    <StyledAgeContainer>
      <StyledAgeOverLay></StyledAgeOverLay>
      <StyledOverlayContainer className='custonamount'>
        <StyledOverlayHead>
          <StyledHead>
            <h4>Custom Amount</h4>
            {props.betType == 'single' ? (
              <h6>
                Keep pesos 5 or enter multiples of 5 in the amount field to
                place bets.
              </h6>
            ) : (
              <h6>
                Keep pesos 30 or enter multiples of 6 in the amount field to
                place bets.
              </h6>
            )}
            <Link to={'#'} onClick={() => closeCustomAmountPopup()}>
              <PopUpCloseIconSvg />
            </Link>
          </StyledHead>
        </StyledOverlayHead>
        <StyledOverlayBody>
          <StyledCustomInput>
            <div
              className={`${
                amountValidation ? 'custominput custom-error' : 'custominput'
              }`}
            >
              <span className='straighticon'>&#8369;</span>
              {customBetAmount}
            </div>
            {amountValidation &&
              (props.betType == 'single' ? (
                <p className='custom-error-message'>
                  Please provide a valid custom amount and multiples of 5
                </p>
              ) : (
                <p className='custom-error-message'>
                  Please provide a valid custom amount and multiples of 6
                </p>
              ))}
          </StyledCustomInput>
          <StyledCustonNumber>
            <ul>
              {keyBoardCount.length > 0 &&
                keyBoardCount.map((item: any, index: any) => {
                  return (
                    item != 0 && (
                      <li key={index}>
                        <Link to={'#'} onClick={() => customAmount(item)}>
                          {item}
                        </Link>
                      </li>
                    )
                  );
                })}
              <li>
                <Link to={'#'} onClick={() => customAmount(0)}>
                  0
                </Link>
              </li>
              <li className='removeback'>
                <Link to={'#'} onClick={() => clearAmount()}>
                  <NumberRemovebackIconSvg />
                </Link>
              </li>
            </ul>
            <Link
              className='confirmbtn'
              to={'#'}
              onClick={() => confirmcustomAmount()}
            >
              Confirm
            </Link>
          </StyledCustonNumber>
        </StyledOverlayBody>
      </StyledOverlayContainer>
    </StyledAgeContainer>
  );
};

export default CustomAmount;
