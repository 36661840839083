import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import {
  StyledFooter,
  StyledFooterSupport,
  StyledFooterMain,
  StyledFooterContainer,
  StyledFooterCopyRight,
  StyledFooterMenuListContainer,
  StyledFooterMenuWrapper,
  StyledFooterMenu,
  StyledFooterLogo,
  StyledFooterSocialMedia,
  StyledFooterPartnered,
} from './style';
import {
  LogoutIconSvg,
  QueueIconSvg,
  SupportIconSvg,
  TicketsIconSvg,
  HomeIconSvg,
} from '@/shared/svg';
import CookieStorageService from '@/services/CookieStorageService';
import AuthService from '@/services/AuthService';
import { setIsAuthenticatedData, resetUser } from '@/redux/slices/login';
import { history } from '@/helpers/history';
import { changeModule } from '@/redux/slices/module';

interface childProps {
  placeBet: Function;
  enableQueue: Function;
  queueList: any;
  enableQueuePopup: boolean;
}

const Footer = (props: childProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    //selectMenu();
  }, []);

  const placeBets = async () => {
    if (props.queueList.length > 0 && props.enableQueuePopup) props.placeBet();
  };

  const enableQueue = async () => {
    props.enableQueue();
  };

  return (
    <StyledFooter className='footer'>
      <StyledFooterMain>
        <ul>
          <li>
            <Link to='/dashboard'>
              <HomeIconSvg />
              <p>Home</p>
            </Link>
          </li>
          <li
            className={`${
              props.enableQueuePopup != undefined && props.enableQueuePopup
                ? 'active'
                : ''
            }`}
          >
            <Link to={'#'} onClick={() => enableQueue()}>
              {props.queueList != undefined && props.queueList.length > 0 && (
                <span className='notification'>{props.queueList.length}</span>
              )}
              <QueueIconSvg />
              <p>Queue</p>
            </Link>
          </li>
        </ul>
        <ul className='placebet'>
          <li
            className={`${
              props.queueList != undefined &&
              props.queueList.length > 0 &&
              props.enableQueuePopup
                ? ''
                : 'disabled'
            }`}
          >
            <Link className='placebetview' to={'#'} onClick={() => placeBets()}>
              <span className='placebetbutton'>
                <p>PLACE</p>
                {}
                <h6>BET</h6>
              </span>
            </Link>
          </li>
        </ul>
        <ul>
          <li>
            {/* <Link to='/ticketshistory'> */}
            <Link to={'#'}>
              <TicketsIconSvg />
              <p>Tickets</p>
            </Link>
          </li>
          <li>
            {/* <Link to='/support'> */}
            <Link to='/support'>
              <SupportIconSvg />
              <p>Support</p>
            </Link>
          </li>
        </ul>
      </StyledFooterMain>
    </StyledFooter>
  );
};

export default Footer;
