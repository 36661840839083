import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  BannerTwitterSvg,
  BannerFacebookSvg,
  BannerTelegramSvg,
  BannerYouTubeSvg,
  BannerInstagramSvg,
  BannerAnchorSvg,
  BannerSpotifySvg,
  EGamesViewSvg,
  ArrowRightSvg,
  RealTimeGamesSvg,
  ComingSoonSvg,
  WEGamesSvg,
  EvolutionGamesSvg,
  WEGamesViewSvg,
  RealTimeGamViewsSvg,
  TripleCrownViewSvg,
  SpecialgamesSvg,
  MatchRacingSvg,
} from '@/shared/svg';
import {
  StyledContainerFluid,
  StyledBannerContainer,
  StyledLargeGameContainer,
  StyledBannerImg,
  StyledBannerAdd,
  StyledBannerText,
  StyledBannerAddTitle,
  StyledBannerAddSocialIcons,
  StyledBannerFotter,
  StyledBannerLogo,
  StyledLoader,
  StyledEvlViewAll,
  StyledRtgGameList,
  StyledRtgView,
  StyledComingSoon,
  StyledComingSoonGame,
  StyledTwoGameCard,
  StyledTwoGameList,
  StyledTripleView,
  StyledLargeGameList,
  StyledEvlGameList,
  StyledEvlView,
} from './style';
import CookieStorageService from '@/services/CookieStorageService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { changeModule } from '@/redux/slices/module';
import { useAppSelector } from '@/hooks/useAppSelector';

const Home = () => {
  
  useEffect(() => {
   
  }, []);

  return (
    <>
      <StyledContainerFluid>                
        <StyledLoader></StyledLoader>
        <StyledLargeGameContainer>                                                         
        </StyledLargeGameContainer>
      </StyledContainerFluid>
    </>
  );
};

export default Home;
