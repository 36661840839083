import styled, { device, themeGet } from '@/shared/styled';

export const StyledDashboardHead = styled.div`
z-index: 20;
top: 0px;
left: 0px;
right: 0px;
width: 100%;
position: sticky;
height: 58px;
background: ${themeGet('colors.topheaderbackground')};
border-bottom: 1px solid rgba(255, 255, 255, 0.15);
font-size: 24px;
font-weight: 600;
font-family: 'Sora', sans-serif;
color:${themeGet('colors.white')};
text-align: center;
display: flex;
align-items: center;
justify-content: center;
.backarrow{
  position: absolute;
  left: 16px;
  top: 10px;
}
`;

export const StyledTicketContainerFluid = styled.div`
  //background: ${themeGet('colors.bodybackground')};
  min-height: calc(100vh - 135px);
  padding: 20px 10px 20px 10px;
  border-radius: 26px 26px 0px 0px;
  background: linear-gradient(180deg, #F3F3FC 0%, #DFDFEC 91.6%);
  // box-shadow: 0px -20px 60px 0px rgba(0, 0, 0, 0.15);
  margin-top: -16px;
  position: relative;
  z-index: 999;
  ${device.xlarge} {
    float: inherit;
    margin: 0px auto;
  }
`;

export const StyledTicketContainer = styled.div`
  width: 100%;
  background: ${themeGet('colors.white')};
  border-radius: 8px;
`;

export const StyledTicketHeader = styled.div`
  width:100%;
  padding: 25px 20px 0px 20px;
  ul{
    list-style: none;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    li{
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      span{
        color: ${themeGet('colors.black')};
        font-family: 'Sora', sans-serif;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        //flex: 1;
      }
      strong{
        color: ${themeGet('colors.black')};
        text-align: right;
        font-family: 'Sora', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        flex: 1;
        white-space: nowrap;
      }
    }
  }
`;

export const StyledTicketCombination = styled.div`
  width:100%;
  padding: 25px 20px 20px 20px;
  ul{
    list-style: none;
    width: 100%;
    li{
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      span{
        color: ${themeGet('colors.black')};
        font-family: 'Sora',sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        flex: 1;
        letter-spacing: 1.6px;
      }
      .header_col{
        font-size: 15px;
        font-weight: 400;
        color: ${themeGet('colors.black')};
      }
      strong{
        color: ${themeGet('colors.black')};
        text-align: right;
        font-family: 'Sora',sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.048px;
      }
    }
    .header_row{
      margin-bottom: 18px;
    }
  }
`;
export const StyledTicketMiddle = styled.div`
position: relative;
width: 100%;
display: block;
z-index: 2;
span {
  position: absolute;
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 50%;
  top: 0;
  transform: translateY(-50%);
  background: linear-gradient(180deg, #F3F3FC 0%, #DFDFEC 91.6%);
  //background-color: ${themeGet('colors.bodybackground')};
}

span:nth-child(1) {
  width: 30px;
  height: 30px;
  left: -15px;
}
span:nth-child(2) {
  width: 30px;
  height: 30px;
  right: -15px;
}
span:nth-child(3) {
  left: 40px;
}
span:nth-child(4) {
  left: 80px;
}
span:nth-child(5) {
  left: 120px;
}
span:nth-child(6) {
  left: 160px;
}
span:nth-child(7) {
  left: 200px;
}
span:nth-child(8) {
  left: 240px;
}
span:nth-child(9) {
  left: 280px;
}
span:nth-child(10) {
  left: 320px;
}
span:nth-child(11) {
  left: 360px;
}
${device.MobToSLL}{
  span:nth-child(10) {display:none;}
  span:nth-child(11) {display:none;}
}
`;
export const StyledTicketTransaction = styled.div`
  width:100%;
  padding: 25px 20px 20px 20px;
  // background: rgba(220, 220, 220, 0.78);
  border-radius: 0px 0px 10px 10px;

  h3{
    color: ${themeGet('colors.black')};
    text-align: center;
    font-family: 'Sora',sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 22px;
  }
  .qrcode_info{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  ul{
    width: 100%;
    list-style: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15)
    margin-bottom: 16px;
    li{
      margin-bottom: 16px;
      display: flex;
      span{
        color: rgba(0, 0, 0, 0.85);;
        font-family: 'Sora',sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        flex: 1;
      }
      strong{
        color: ${themeGet('colors.black')};
        text-align: right;
        font-family: 'Sora',sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px; 
      }
    }
  }
  p{
    display: inline-block;
    margin-right: 12px;
    ${device.MobToMmx} {
      &:last-child{
        margin-right: 0px;
      }
    }
    ${device.MobToSmx} {
      &:last-child{
        margin-right: 0px;
      }
    }
    span{
      color: ${themeGet('colors.black')};
      font-family: 'Sora',sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-left: 5px;
      ${device.MobToMmx} {
        font-size: 13px;
        line-height: 19px;
      }
      ${device.MobToSmx} {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
`;