import styled, { device, themeGet } from '@/shared/styled';

export const StyledDashboardHead = styled.div`
z-index: 20;
top: 0px;
left: 0px;
right: 0px;
width: 100%;
position: sticky;
height: 58px;
background: ${themeGet('colors.topheaderbackground')};
border-bottom: 1px solid rgba(255, 255, 255, 0.15);
font-size: 24px;
font-weight: 600;
font-family: 'Sora', sans-serif;
color:${themeGet('colors.white')};
text-align: center;
display: flex;
align-items: center;
justify-content: center;
.backarrow{
  position: absolute;
  left: 16px;
  top: 10px;
}
`;

export const StyledQueueContainerFluid = styled.div`
  transition: all 0.3s;
  background: ${themeGet('colors.bodybackground')};
  //min-height: calc(100vh - 60px);
  //padding: 20px 10px 30px;
  //border-radius: 26px 26px 0px 0px;
  background: linear-gradient(180deg, #F3F3FC 0%, #DFDFEC 91.6%);
  box-shadow: 0px -20px 60px 0px rgba(0, 0, 0, 0.15);
  //margin-top: -16px;
  position: relative;
  z-index: 999;
  width: 100%;  
  position: absolute;
  top: 58px;
  height: 100%;
  // ${device.xlarge} {
  //   float: inherit;
  //   margin: 0px auto;
  // }
  .closeicon{
    height: 24px;
    display: flex;
    left: 16px;
    top: 3px;
    width: 24px;
    position: absolute;
    svg{
      path{
        fill: #000;
      }
    }
  }
`;

export const StyledQueContainerView = styled.div`
display: flex;
flex-direction: column;
height::100%;
//height: calc(100vh - 90px);
overflow: auto;
margin-top: 14px;
`;

export const StyledQueHead = styled.div`
display: flex;
position: relative;
justify-content: center;
flex-direction: row;
padding: 0px 16px;
align-items: center;
margin-bottom: 4px;
h4{
  color:${themeGet('colors.NumberGameTextcolor')};
  font-size: 20px;
  font-weight: 600;
  font-family: 'Sora',sans-serif;
  line-height: 30px;
}

`;

export const StyledQueueContainer = styled.div`
  width:100%;
  display: flex;y
  min-height: calc(100vh - 130px);
`;

export const StyledQueueListContainer = styled.div`
  width:100%;
  padding: 10px 16px;
`;

export const StyledQueueListHeader = styled.div`
  width:100%;
  display: flex;
  border-radius: 20px 20px 0px 0px;
  background: linear-gradient(270deg, #A0ADB5 0.03%, #B7C6CF 99.98%);
  // border-radius: 10px 10px 0px 0px;
  // background: #1E3A8A;
  padding: 10px 16px;
  justify-content: space-between;
  //margin-bottom: 10px;
  strong{
    color: ${themeGet('colors.white')};
    font-family: 'Sora', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
  .comb_head{
   // width: calc(100% - 116px);
   display: flex;
   width:100%;
   span{

   }
  }
  .amount_head{
    //width:90px;
    display: flex;
    width:70%;
  }
  button{
    display: flex;
    //flex: 1;
    background: none;
  }
`;

export const StyledQueueListView = styled.div`
width:100%;
background: ${themeGet('colors.white')};
border-radius: 0px 0px 20px 20px;
border: 2px solid #DCE1E8;
padding: 6px 16px 8px 16px;
border-top: none;
.confirmbtnview{
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 12px 10px 6px 10px;
}
.closeiconbtn{
  border-radius: 10px;
  border: 2px solid #4165FF;
  // background: #4165FF;
  height:42px;
  border-radius: 10px;
  display: flex;
  color:${themeGet('colors.NumberGameTextcolor')};
  font-size: 16px;
  font-weight: 600;
  font-family: 'Sora', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
}
.confirmbtn{
  height: 42px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Sora', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 145px;
  border-radius: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  background: linear-gradient(270deg, #3B60FE 0.03%, #5573FE 99.98%);
  p{
    line-height: initial;
    font-weight: 600;
  }
}
`;

export const StyledQueueListContent = styled.div`
  width:100%;
  //background: ${themeGet('colors.white')};
  width: 100%;
  overflow-y: auto;
  max-height: 380px;
  display: flex;
  flex-direction: column;
  
  .noqueue{
    border: 0px;
    text-align: center;
    justify-content: center;
    font-size: 14px;
  }
  .bettype{
    position: absolute;
    font-size: 10px;
    bottom: -15px;
    font-weight: 600;
    color: #F54646;
  }
  p{
    //background: ${themeGet('colors.white')};
    padding: 10px 16px 10px 5px;
    display: flex;
    //margin-bottom: 10px;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #A1AEB6;
    justify-content: space-between;
    position: relative;
    .closebtn{
      position: absolute;
      right: 0px;
      top:12px;
    }
    strong{
      color: #395065;
      font-family: 'Sora', sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 2px;
    }
    .comb_head{
      //width: calc(100% - 110px);
      display: flex;
      width:100%;
      position: relative;
    }
    span{
      color: #395065;
      font-family: 'Sora', sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.048px;
    }
    .amount_head{
      //width: 90px;
      display: flex;
      width:70%;
    }
    button{
      background:none;
    }
  }
`;

export const StyledQueueListFooter = styled.div`
  width: 100%;
  padding: 10px 16px 10px 5px;
  height:50px;
  display: flex;
  align-items: center;
  background: #F1F1F8;
  strong{
    color: ${themeGet('colors.black')};
    font-family: 'Sora', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.22px;
    display: inline-block;
  }
  .total_text{
    //width: calc(100% - 120px);
    display: flex;
    width:100%;
  }
  .total_amount{
    //width: 120px;
    text-align: right;
    display: flex;
    width:70%;
  }
`;

export const StyledModalContainer = styled.div`
  width:100%;
  display:none;
`;

export const StyledModalOverlay = styled.div`
  width:100%;
  height:100%;
  position:fixed;
  top: 62px;
  left:0px;
  background: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(6px);
  z-index: 100;
`;

export const StyledModalContent = styled.div`
  width: 343px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: #26334D;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
`;

export const StyledModalHeading = styled.div`
  width:100%;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 8px;
  h3{
    color: #FFF;
    font-family: 'Sora', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 8px;
  }
`;

export const StyledModalInfo = styled.div`
  width:100%;
  p{
    color: rgba(255, 255, 255, 0.45);
    text-align: center;
    font-family: 'Sora', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 32px;
  }
`;

export const StyledTextInput = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  label{
    color: #C2C9D6;
    font-family: 'Sora', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 10px;
  }
  input{
    border-radius: 6px;
    border: 1px solid #7885A0;
    background: #1C2335;
    width: 100%;
    padding: 13px 16px;
    display: flex;
    align-items: center;
    height: 52px;
    color: #B1B9C8;
    font-family: 'Sora', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: 0.042px;
  }
`;

export const StyledButtonWrap = styled.div`
  width:100%;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  button{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    padding: 16px 10px;
    border-radius: 8px;
    font-family: 'Sora', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .cancel_btn{
    background: transparent;
    border: 1px solid #F37020;
    color: #F37020;
  }
  .placebet_btn{
    background: #F37020;
    color: #fff;
  }
`;
