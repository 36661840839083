import React, { useState, useEffect, useMemo } from 'react';
import {
  StyledGameContainerFluid,
  StyledGameNumber,
  StyledStraightBody,
  StyledStraightHead,
  StyledStraightInput,
  StyledEventTime,
  StyledGameAmountView,
  StyledGameAmount,
  StyledGameInfo,
  StyledNumberText,
  StyledGameInputText,
  StyledGameRow,
  StyledPopupView,
  StyledGameInfopop,
  StyledStraightView,
  StyledGameMainView,
} from './style';
import AuthService from '@/services/AuthService';
import { useAppSelector } from '@/hooks/useAppSelector';
import { Link, useParams } from 'react-router-dom';
import { InputGroup } from 'react-bootstrap';
import { ButtonSpinner, Input, Label } from '@/components';
import {
  NumberRemovebackIconSvg,
  GameNumberOneIconSvg,
  InfoGameSvg,
  CloseIconSvg,
  GameNumberTwoIconSvg,
  GameNumberThreeIconSvg,
} from '@/shared/svg';
import Ticket from '../ticket/ticket';
import moment from 'moment';
import Footer from '@/layout/footer';
import Queue from '../queue/queue';
import {
  setQueueEmpty
} from '@/redux/slices/login';
import { useDispatch } from 'react-redux';


interface childProps {
  placeBets: Function;
  removeQueueIndex: number;
  queueList: any;
  enableQueuePopup: boolean;
}
interface queueHistory {
  entryType: 'sale';
  betAmount: any;
  unitBet: 5;
  totalAmount: any;
  runners: any;
  runnersDisplay: any;
  runnerQueue: any;
  betQuantity: 1;
  programId: any;
  poolName: any;
  action: 'submit';
  eventId: any;
  venueCode: any;
  groupCode: any;
  customerId: any;
}

var queueData: queueHistory[] = [];
const Game = (props: childProps) => {
  const dispatch = useDispatch();
  const [ballsCount, setBallsCount] = useState<any>([]);
  const [ballValue, setBallValue] = useState<any>('');
  const [betAmount, setBetAmount] = useState<any>('5');
  const [responseData, setResponseData] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [groupCodeData, setGroupCodeData] = useState('');
  const [category, setCategory] = useState('');
  const [queuedData, setQueuedData] = useState([]);
  const [programId, setProgramId] = useState('');
  const [eventId, setEventId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [eventName, setEventName] = useState('');
  const [venueCode, setVenueCode] = useState('');
  const [enablePhonePopup, setEnablePhonePopup] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [eventsData, setEventsData] = useState([]);
  const [success, setSuccess] = useState(false);
  const [eventActiveIndex, setEventActiveIndex] = useState(0);
  const [error, setError] = useState(false);
  const [enableQueuePopup, setEnableQueuePopup] = useState(false);
  const [enableBetSpinner, setEnableBetSpinner] = useState(false);
  const { userInfo } = useAppSelector(state => state.auth);
  const [inputColor, setInputColor] = useState(false);

  const params = useParams();
  const currentTableData = useMemo(() => {
    const num = Number(params.count);
    var N = num;
    var arrayCount = Array.apply(null, { length: N }).map(Number.call, Number);

    setBallsCount(arrayCount);
    setCategory(params.category);
  }, [params.count]);

  const placeVal = (ind: any) => {
    let str = '';
    switch (ind) {
      case 0:
        str = 'st';
        break;
      case 1:
        str = 'nd';
        break;
      case 2:
        str = 'rd';
        break;
      default:
        str = 'th';
        break;
    }
    return str;
  };

  const onKeyPress = (val: any) => {
    if (venueCode == '') return;
    if (val == 'clear') {
      setBallValue('');
      return;
    }
    const v = ballValue + val;
    //const v = val;
    if (v < 100 && ballsCount.length == 1) setBallValue(v);
    if (convertNumtoArray(v).length <= ballsCount.length) setBallValue(v);
  };

  const convertNumtoArray = (val: any) => {
    if (val == '') return '';
    if (ballsCount.length > 1) {
      const num = val;
      const arrOfStrs = Array.from(String(num));
      const arrOfNums = arrOfStrs.map(str => Number(str));
      return arrOfNums; // [ 1, 2, 3, 4 ]
    } else {
      return val;
    }
  };

  const handleChange = (e: { target: any }) => {
    setBetAmount(e.target.value);
  };

  const changeColor = () => {
    setInputColor(true);
  };


  const handlePhoneChange = (e: { target: any }) => {
    setPhoneNumber(e.target.value);
  };

  const confirmPlaceBet = async () => {
    if (phoneNumber == '') {
      setPhoneNumberError(true);
      return;
    }

    if (phoneNumber != '' && phoneNumber.length != 10) {
      setPhoneNumberError(true);
      return;
    }

    setEnableBetSpinner(true);

    if (queuedData.length > 0) {
      let sumTotal = 0;
      queuedData.map((item: any, index: any) => {
        if (item.phoneNumber == undefined) item.phoneNumber = '';
        if (item.sumTotal == undefined) item.sumTotal = 0;

        sumTotal += parseInt(item.totalAmount);
        item.phoneNumber = phoneNumber;
      });
      queuedData[0].sumTotal = sumTotal;
    }


    let filteredWinData = [];
    filteredWinData = queuedData.filter((item: any) => item.poolName === 'WIN');

    let filteredTanData = [];
    filteredTanData = queuedData.filter((item: any) => item.poolName === 'TAN');

    const responses = await Promise.all([fetch(filteredWinData), fetch(filteredTanData)])



    // const response = await AuthService.submitBet(queuedData);
    // if (response.status) {      
    //   setSuccess(true);
    //   setEnablePhonePopup(false);
    //   setResponseData(queuedData);
    //   setGroupCodeData(response.data);      
    //   setEnableQueuePopup(false);
    //   setEnableBetSpinner(false);
    //   setQueuedData([]);
    //   //dispatch(setQueueEmpty(0));
    // } else {
    //   setError(true);
    //   setErrorMessage(response.data);
    //   setEnablePhonePopup(false);
    //   //setQueuedData([]);
    //   setEnableQueuePopup(false);
    //   setEnableBetSpinner(false);
    // }
  };

  const fetch = async (data:any) => {
    if(data.length == 0)
    return;
    const response = await AuthService.submitBet(data);
    if (response.status) {      
      setSuccess(true);
      setEnablePhonePopup(false);
      setResponseData(queuedData);
      setGroupCodeData(response.data);      
      setEnableQueuePopup(false);
      setEnableBetSpinner(false);
      setQueuedData([]);
      //dispatch(setQueueEmpty(0));
    } else {
      setError(true);
      setErrorMessage(response.data);
      setEnablePhonePopup(false);
      //setQueuedData([]);
      setEnableQueuePopup(false);
      setEnableBetSpinner(false);
    }
  }

  const closePhoneNumber = () => {
    setEnablePhonePopup(false);
  };

  const placeBet = async () => {
    setPhoneNumber('');
    setPhoneNumberError(false);
    setSuccess(false);
    setError(false);
    setEnablePhonePopup(true);
  };

  const SelectProgramData = async (data: any, ind: number) => {
    setProgramId(data.programId);
    setEventId(data.name.slice(5, 6));
    setEventName(data.startTime);
    setEventActiveIndex(ind);
  };

  const getEventsData = async () => {
    const response = await AuthService.events(category);
    if (response.data != undefined && response.data.events.length > 0) {
      setVenueCode(response.data.venueCode);
      setEventsData(response.data.events);
      SelectProgramData(response.data.events[0], 0);
    }
  };

  const enableQueue = async () => {
    setEnableQueuePopup(true);
  };

  const addToQueue = async () => {
    if (venueCode == null) return;
    let runnerForBackend: any;
    let displayTicket: any;
    if (category === 'WIN') {
      displayTicket = ballValue;
      runnerForBackend = parseInt(ballValue) + 1;
    } else if (category === 'TAN') {
      if (ballsCount.length > 1) {
        const num = ballValue;
        const arrOfStrs = Array.from(String(num));
        displayTicket = arrOfStrs.join('-');
        const arrOfNums = arrOfStrs.map(str => Number(parseInt(str) + 1));
        runnerForBackend = arrOfNums.join('-');
      }
    }

    if (ballsCount.length > 1) {
    if(ballsCount.length != ballValue.length)
    return;
    }

    const queuehistory: queueHistory = {
      entryType: 'sale',
      betAmount: betAmount,
      unitBet: 5,
      totalAmount: betAmount,
      runners: runnerForBackend,
      runnersDisplay: runnerForBackend,
      runnerQueue: displayTicket,
      betQuantity: 1,
      programId: programId,
      poolName: category,
      action: 'submit',
      eventId: eventId,
      venueCode: venueCode,
      groupCode: '',
      customerId: 'accae285-515a-40a6-89e8-0265515b6da9',
    };
    queueData = [...queueData, queuehistory];
    setQueuedData(queueData);
    setBallValue('');
  };

  const closePopup = async () => {
    setEnableQueuePopup(false);
    setError(false);
    setErrorMessage('');
  };

  useEffect(() => {
    getEventsData();
  }, []);

  return (
    <>
      {success && (
        <StyledGameContainerFluid>
          <StyledGameMainView>
            {/* <Ticket
              eventName={eventName}
              phoneNumer={phoneNumber}
              responseData={responseData}
              groupCodeData={groupCodeData}
            /> */}
          </StyledGameMainView>
        </StyledGameContainerFluid>
      )}
      {enablePhonePopup && (
        <>
          <StyledPopupView>
            <StyledGameInfopop>
              <h4>Confirm</h4>
              <p>Please enter Player phone number</p>
              <div className='loginform'>
                <Label display='block' mb='5px' htmlFor='email'>
                  Phone Number
                </Label>
                <span className='phonecode'>+ 63</span>
                <Input
                  id='phoneNumber'
                  type='text'
                  placeholder='Phone Number'
                  name={'phoneNumber'}
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  maxlength={10}
                  className={`${phoneNumberError ? 'straight error' : 'straight'
                    }`}
                />
                {phoneNumberError && (
                  <p className='error'> Enter Phone number or Valid Number</p>
                )}
                <div className='buttonview'>
                  <Link
                    className='closebtn'
                    to={'#'}
                    onClick={() => closePhoneNumber()}
                  >
                    Cancel
                  </Link>
                  <Link
                    className='confirmbtn'
                    to={'#'}
                    onClick={() => confirmPlaceBet()}
                  >
                    {enableBetSpinner ? <ButtonSpinner /> : null}Confirm
                  </Link>
                </div>
              </div>
            </StyledGameInfopop>
          </StyledPopupView>
        </>
      )}
      {error && (
        <StyledPopupView>
          <StyledGameInfopop>
            <Link className='closeicon' to='' onClick={() => closePopup()}>
              <CloseIconSvg />
            </Link>
            <p>{errorMessage}</p>
          </StyledGameInfopop>
        </StyledPopupView>
      )}
      {!success && (<StyledGameContainerFluid>
        <StyledGameMainView>
          {!success && (
            <>
              <StyledEventTime>
                <p>Event</p>
                <ul>
                  {eventsData &&
                    eventsData.map((item: any, index: any) => {
                      return (
                        <li
                          key={index}
                          className={`${eventActiveIndex === index ? 'active' : ''
                            }`}
                        >
                          <Link
                            to={'#'}
                            onClick={() => SelectProgramData(item, index)}
                          >
                            {moment(item.startTime.slice(0, 2), 'HH').format(
                              'h'
                            )}
                            <span>
                              {moment(item.startTime.slice(0, 2), 'HH').format(
                                'a'
                              )}
                            </span>
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </StyledEventTime>
              <StyledGameInfo>
                {params.count == '1' && <p>Pick a number from 0 - 99</p>}
                {params.count > '1' && <p>Pick a number from 0 - 9</p>}
                <Link to=''>
                  <InfoGameSvg />
                </Link>
              </StyledGameInfo>

              <StyledGameAmountView>
                <StyledGameAmount>
                  <StyledGameRow>
                    {ballsCount.map((item: any, index: any) => {
                      return (
                        <>
                          <StyledGameInputText key={index}>
                            <Link to=''>
                              {index == 0 ? (
                                <GameNumberOneIconSvg />
                              ) : index == 1 ? (
                                <GameNumberTwoIconSvg />
                              ) : index == 2 ? (
                                <GameNumberThreeIconSvg />
                              ) : (
                                ''
                              )}
                            </Link>
                            {ballsCount.length == 1 && (
                              <>
                                {ballValue === '' ? (
                                  <p>--</p>
                                ) : (
                                  <p>{convertNumtoArray(ballValue)}</p>
                                )}
                              </>
                            )}
                            {ballsCount.length > 1 && (
                              <>
                                {ballValue === '' ? (
                                  <p>-</p>
                                ) : (
                                  <p>{convertNumtoArray(ballValue)[item]}</p>
                                )}
                              </>
                            )}
                          </StyledGameInputText>
                        </>
                      );
                    })}
                  </StyledGameRow>
                  <StyledNumberText>                    
                    {ballValue != '' &&
                      ballsCount.map((item: any, index: any) => {
                        return (
                          <>
                            {ballsCount.length == 1 && (
                              <>
                                <p key={index}>{convertNumtoArray(ballValue)}</p>
                              </>
                            )}
                            {ballsCount.length > 1 && (
                              <>
                                <p key={index}>{convertNumtoArray(ballValue)[item]}</p>
                              </>
                            )}
                          </>
                        );
                      })}
                  </StyledNumberText>
                </StyledGameAmount>
              </StyledGameAmountView>
              <StyledStraightView>
                <StyledStraightInput>
                  <StyledStraightHead>
                    <p>Select amount field to enter multiples of 5</p>
                  </StyledStraightHead>
                  <StyledStraightBody>
                    <InputGroup>
                      <span className='straighticon'>&#8369;</span>
                      <Input
                        id='betAmount'
                        type='text'
                        placeholder=''
                        name={'betAmount'}
                        value={betAmount}
                        onChange={handleChange}
                        className='straight'
                        //onClick={changeColor}
                        //className={inputColor ? 'straightFocus' : 'straight'}
                        //readonly
                      />
                    </InputGroup>

                  </StyledStraightBody>
                </StyledStraightInput>
              </StyledStraightView>
              <StyledGameNumber>
                <ul>
                  <li className={`${venueCode == '' ? 'disabled' : ''}`}>
                    <Link to={''} onClick={() => onKeyPress(1)}>
                      1
                    </Link>
                  </li>
                  <li className={`${venueCode == '' ? 'disabled' : ''}`}>
                    <Link to={''} onClick={() => onKeyPress(2)}>
                      2
                    </Link>
                  </li>
                  <li className={`${venueCode == '' ? 'disabled' : ''}`}>
                    <Link to={''} onClick={() => onKeyPress(3)}>
                      3
                    </Link>
                  </li>
                  <li className={`${venueCode == '' ? 'disabled' : ''}`}>
                    <Link to={''} onClick={() => onKeyPress(4)}>
                      4
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li className={`${venueCode == '' ? 'disabled' : ''}`}>
                    <Link to={''} onClick={() => onKeyPress(5)}>
                      5
                    </Link>
                  </li>
                  <li className={`${venueCode == '' ? 'disabled' : ''}`}>
                    <Link to={''} onClick={() => onKeyPress(6)}>
                      6
                    </Link>
                  </li>
                  <li className={`${venueCode == '' ? 'disabled' : ''}`}>
                    <Link to={''} onClick={() => onKeyPress(7)}>
                      7
                    </Link>
                  </li>
                  <li className={`${venueCode == '' ? 'disabled' : ''}`}>
                    <Link to={''} onClick={() => onKeyPress(8)}>
                      8
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li className={`${venueCode == '' ? 'disabled' : ''}`}>
                    <Link to={''} onClick={() => onKeyPress(9)}>
                      9
                    </Link>
                  </li>
                  <li className={`${venueCode == '' ? 'disabled' : ''}`}>
                    <Link to={''} onClick={() => onKeyPress(0)}>
                      0
                    </Link>
                  </li>
                  <li
                    className={`${venueCode == '' ? 'disabled removeback' : 'removeback'
                      }`}
                  >
                    <Link to={''} onClick={() => onKeyPress('clear')}>
                      <NumberRemovebackIconSvg />
                    </Link>
                  </li>
                  <li
                    className={`${ballValue === '' ? 'disabled addqueue' : 'active addqueue'
                      }`}
                  >
                    <Link to={'#'} onClick={() => addToQueue()}>
                      <p>ADD TO</p>
                      <h6>QUEUE</h6>
                    </Link>
                  </li>
                </ul>
              </StyledGameNumber>
            </>
          )}
        </StyledGameMainView>
      </StyledGameContainerFluid>)}
      {/* {enableQueuePopup && (
        <Queue queueList={queuedData} closePopup={closePopup} />
      )} */}
      <Footer
        placeBet={placeBet}
        enableQueue={enableQueue}
        queueList={queuedData}
        enableQueuePopup={enableQueuePopup}
      />
    </>
  );
};

export default Game;
