import styled, { device, themeGet } from '@/shared/styled';

export const StyledDashboardHead = styled.div`
z-index: 20;
top: 0px;
left: 0px;
right: 0px;
width: 100%;
position: sticky;
height: 58px;
background: ${themeGet('colors.topheaderbackground')};
border-bottom: 1px solid rgba(255, 255, 255, 0.15);
font-size: 24px;
font-weight: 600;
font-family: 'Sora', sans-serif;
color:${themeGet('colors.white')};
text-align: center;
display: flex;
align-items: center;
justify-content: center;
`;

export const StyledDashboardmainView = styled.div`
 //min-height: calc(100vh - 60px);
 overflow: auto;
 display: flex;
 flex-direction: column;

`;

export const StyledDashboardView = styled.div`
display: flex;
  flex-direction: column;
  margin-top: 16px;

`;

export const StyledDashboardContainerFluid = styled.div`
  //background: ${themeGet('colors.bodybackground')};
  background-image: url("https://static-web.fra1.cdn.digitaloceanspaces.com/numbergame/background.svg");
  background-repeat: repeat-y;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  background-color:#DFDFEC;
  //background-color: linear-gradient(180deg, #F3F3FC 0%, #DFDFEC 91.6%);
  box-shadow: 0px -20px 60px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 999;
  width: 100%;
  // min-height: calc(100vh - 60px);
 // padding: 20px 10px 30px;
  // ${device.xlarge} {
  //   width: 1280px !important;
  //   float: inherit;
  //   margin: 0px auto;
  // }
  .textalignright {
    text-align: right;
  }
  .incomeamount {
    color: #529652;
  }
  ${device.TabToLg}{
    padding-top: 0px;
    padding-left:0px;
    padding-right:0px;
    .mywalletfilter{
      display:none !important;
    }
  }
  .mobileselectview{
    display:none;
  }
`;

export const StyledDashboardContainer = styled.div`
padding:0px 16px;
display: flex;
min-height: calc(100vh - 76px);
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  overflow: auto;
h3{
  color: ${themeGet('colors.NumberGameTextcolor')};;
  text-align: center;
  font-family: 'Sora', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  margin-top: 20px;
  padding-bottom:30px;
}
ul{
  gap: 28px;
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 0px;
  li{
    background: ${themeGet('colors.white')};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;
    border: 2px solid #b6c0ce;
    transition: .3s ease-in all;
    //height: calc(100% / 3);
    &:hover{
      border: 2px solid #DCE1E8;
    }
    a{
      display: flex;
      width: 100%;
      height: 100%;
      padding: 20px 0px 30px 0px;
      //min-height:196px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    h5{
      color:${themeGet('colors.NumberGameTextcolor')};;
      font-family: 'Sora', sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: normal;
      margin-bottom:26px;
    }
    .number_wrap{
      //width: 108px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      //height: 108px;
      small{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 18px;
        color: #000;
        text-align: center;
        font-size: 21.268px;
        font-weight: 700;
        line-height: normal;
        font-family: 'Sora', sans-serif;
        display: flex;
        align-content: center;
        justify-content: center;
      }
    }
    .three_no_wrapper{
      display: flex;
      gap: 20px;
      .lf_mr{
        //margin-left: -20px;
      }
    }
  }
  .active{
    border: 2px solid ${themeGet('colors.topheaderbackground')};
  }
}
   
}

`;
export const StyledUserDetails = styled.div`
display: flex;
flex-direction: column;
text-align: center;
width:100%;
position: fixed;
bottom: 0px;
left: 0px;
.userlogged{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px 0px;
}
p{
  color: #7c899f;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Sora', sans-serif;
  padding-bottom:8px;
}
a{
  border-radius: 20px;
  border: 1px solid #a7b4cc;
  padding: 16px 20px;
  color:${themeGet('colors.NumberGameTextcolor')};
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  svg{
    margin-right: 10px;
  }
}
`;