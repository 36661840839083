import styled, { device, themeGet } from '@/shared/styled';

export const StyledGameContainerFluid = styled.div`
  //background: ${themeGet('colors.bodybackground')};
  display: flex;
  flex-direction: column;
  height: calc(100vh - 135px);
  overflow:auto;
  //height: calc(100vh - 130px);
  // overflow:auto;
  //border-radius: 26px 26px 0px 0px;
  background: linear-gradient(180deg, #F3F3FC 0%, #DFDFEC 91.6%);
  // box-shadow: 0px -20px 60px 0px rgba(0, 0, 0, 0.15);
  //margin-top: -24px;
  position: relative;
  z-index: 999;
  //padding: 20px 16px 30px;
  // ${device.xlarge} {
  //   width: 1280px !important;
  //   float: inherit;
  //   margin: 0px auto;
  // }
  .textalignright {
    text-align: right;
  }
  .incomeamount {
    color: #529652;
  }
  ${device.TabToLg}{
    padding-top: 0px;
    padding-left:0px;
    padding-right:0px;
    .mywalletfilter{
      display:none !important;
    }
    .mobileselectview{
      display:block !important;
      // select{
      //   border-radius: 20px;
      //   border: 1px solid #616972;
      //   color: #B7B9BB;
      //   font-size: 12px;
      //   font-family: ${themeGet('fonts.googleSansMedium')};
      //   padding: 0px 14px;
      //   background:none;
      //   width: 93px;
      //   height: 32px;
      //   background:#000;
      //   option{
      //    background:#000;
      //    color:#fff;
      //    padding:0px;
      //    font-size: 10px;
      //    height: 10px;
      //    font-family: ${themeGet('fonts.googleSansBold')};
      //   }
      // }
    }
  }
  .mobileselectview{
    display:none;
  }
`;

export const StyledGameMainView = styled.div`
display: flex;
flex-direction: column;
height: 100%;
//height: 100%;
//height: calc(100vh - 64px);
//overflow: auto;
//margin-top: 16px;
`;

export const StyledMywalletHeader = styled.div`
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px 10px;
  display: flex;
  ${device.TabToLg}{
    padding-left:16px;
    padding-right:16px;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 20px;
    gap: 20px;
  }
`;

export const StyledWalletType = styled.div`
  display: flex;
  align-items: center;
  ${device.TabToLg}{
    mywalletfilter{
      display:none !important;
    }
  }
  h4 {
    border-right: 1px solid #4c4c4c;
    color: ${themeGet('colors.eventnumberselectedborder')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 22px;
    margin-right: 15px;
    padding-right: 15px;
    display: flex;
    margin-bottom: 0px;
    white-space: nowrap;
    ${device.MobToSd} {
      font-size: 16px !important;
      margin-right: 0px;
      padding-right: 0px !important;
      white-space: break-spaces;
     }
     ${device.MobToSm} {
      font-size: 17px !important;
      margin-right: 0px;
      padding-right: 0px !important;
      white-space: break-spaces;
     }
    ${device.TabToLg} {
      font-size: 18px;
      margin-right: 0px;
      padding-right: 10px;
     }
  }
  p {
    color: #b7b9bb;
    margin-bottom: 0px;
    display: flex;
    font-family: ${themeGet('fonts.googleSansMedium')};
  }

  .allbtn {
    background: ${themeGet('colors.overlaybg')};
    border-radius: 20px;
    color: ${themeGet('colors.white')};
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 12px;
    line-height: 32px;
    padding: 0px 14px;
    text-decoration: none;
    height: 32px;
    display: flex;
  }
  .depositbtn {
    border: 1px solid #616972;
    border-radius: 20px;
    color: #b7b9bb;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 12px;
    line-height: 32px;
    padding: 0px 14px;
    text-decoration: none;
    height: 32px;
    display: flex;
  }
  .withdrawbtn {
    border: 1px solid #616972;
    border-radius: 20px;
    color: #b7b9bb;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 12px;
    line-height: 32px;
    padding: 0px 14px;
    text-decoration: none;
    height: 32px;
    display: flex;
  }
  .accounttype{
    border-radius: 20px;
    border: 1px solid #616972;
    color: #B7B9BB;
    font-size: 12px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    padding: 0px 14px;
    background:none;
    width: auto;
    height: 32px;
  }
  .mywalletfilter{
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const StyledWalletSearch = styled.div`
 .input-group-text{
  padding: 0.36875rem 0.3rem;
  background:${themeGet('colors.pagecontainerinput')};
 }
  input {
    height: 34px;
    background:${themeGet('colors.pagecontainerinput')};
    border:none;
    &:focus{
      background:${themeGet('colors.pagecontainerinput')};
    }
  }
  ${device.TabToLg}{
    width: 100%;
  }
`;

export const StyledWalletDate = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  .allbtn {
    background: ${themeGet('colors.overlaybg')};
    border-radius: 20px;
    color: ${themeGet('colors.white')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 12px;
    line-height: 32px;
    padding: 0px 14px;
    text-decoration: none;
    height: 32px;
    display: flex;
  }
  .depositbtn {
    border: 1px solid #616972;
    border-radius: 20px;
    color: #b7b9bb;
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 12px;
    line-height: 32px;
    padding: 0px 14px;
    text-decoration: none;
    height: 32px;
    display: flex;
  }
  .withdrawbtn {
    border: 1px solid #616972;
    border-radius: 20px;
    color: #b7b9bb;
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 12px;
    line-height: 32px;
    padding: 0px 14px;
    text-decoration: none;
    height: 32px;
    display: flex;
  }
`;

export const StyledMywalletTable = styled.div`
  margin-top: 8px;
  // border: 1px solid ${themeGet('colors.tableheaderborder')};
  // border-radius: 6px;
  // min-height: 290px;
  table {
    width: 100%;
    thead {
      background: ${themeGet('colors.tableheaderbg')};
      tr th {
        border: 1px solid #2c3340;
        border-top: none;
        color: #c8cfd9;
        font-family: ${themeGet('fonts.googleSansMedium')};
        font-size: 12px;
        padding: 10px 8px;
        white-space: nowrap;
        text-transform: capitalize;
        font-weight: inherit;
      }
    }
    tbody {
      tr td {
        border: 1px solid ${themeGet('colors.tableheaderborder')};
        border-top: none;
        color: #b7b9bb;
        padding: 10px 8px;
        height: 58px;
        //white-space: pre-line;
        font-size: 13px;
        font-family: ${themeGet('fonts.googleSansMedium')};
      }
    }
  }
  .bettime{ display:none;}
  .bettypelist{ display:none;}
  .balancetext{display:none;}
  .incomtext{display:none;}
  .balanceaftertext{display:none;}
  .expensestext{display:none;}
  .remarktext{display:none;}
  ${device.TabToLg} {
    .balancetext{display:block;}
    .incomtext{display:block;}
    .balanceaftertext{display:block;}
    .expensestext{display:block;}
    .remarktext{display:block;}
    //display:none;
    border: 0px;
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    table tr {
      //border-bottom: 3px solid ${themeGet('colors.tableheaderborder')} !important;
      display: block;
      //border: 1px solid ${themeGet('colors.tableheaderborder')};
      // margin-bottom: 0.625em;
      // margin-bottom: 15px;
      //background: ${themeGet('colors.pageinfotxtcolor')};
      border-radius: 0px;
      padding:10px 16px 20px 16px;
      border-top: 4px solid #14181D;
      border-bottom: 1px solid #21252D;
    }
    table tbody tr td {
      border:0px;
      //border-bottom: 1px solid ${themeGet('colors.tableheaderborder')};
      display: block;
      text-align: right;
      padding: 0px 0px;
      min-height: 38px;
      color: #b7b9bb;
      font-family: ${themeGet('fonts.googleSansBold')};
      font-size: 13px;
      position: relative;
      height: auto;
    }
    td::before {
      content: attr(data-label);
      display: flex;
      position: absolute;
      top: 8px;
    }
    table td:last-child {
      border-bottom: 0;
    }
    .bettime{ display:block;}
    .bettypelist{display:block;}
    .webticketid{ display:none;}
    .timemobile{display:none;}
    .wallwttime{
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin-bottom: 13px;
      text-align: left;
    }
    .mywalletview{
      color: #B5B5B5;;
      display: flex;
      width:100%;
      flex-direction: column;
      border-top:4px solid #14181D;
      border-bottom:1px solid #21252D;
      padding:20px 16px 30px 16px;
    }
    .balancetext{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      padding:8px 10px;
      font-size: 14px;
      font-family: ${themeGet('fonts.googleSansMedium')};
      background: rgba(27, 31, 37, 0.30);
    }
    .incomtext{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      padding:8px 10px;
      font-size: 14px;
      font-family: ${themeGet('fonts.googleSansMedium')};
    }
    .expensestext{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      padding:8px 10px;
      font-size: 14px;
      font-family: ${themeGet('fonts.googleSansMedium')};
      background: rgba(27, 31, 37, 0.30);
    }
    .balanceaftertext{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      padding:8px 10px;
      font-size: 14px;
      font-family: ${themeGet('fonts.googleSansMedium')};
    }
    .remarktext{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top:2px solid rgba(27, 31, 37, 0.50);
      padding: 8px 10px;
    }
    .textname{
      color: rgba(183, 185, 187, 0.50);
      font-size: 13px;
      font-family: ${themeGet('fonts.googleSansRegular')};
    }
    .bettype{
      border-radius: 20px;
      padding: 0px 14px;
      color: #AB7E65;
      font-size: 12px;
      font-family: ${themeGet('fonts.googleSansMedium')};
      background: linear-gradient(128deg, #1B1F25 0%, rgba(27, 31, 37, 0.30) 64.58%, rgba(27, 31, 37, 0.70) 97.92%);
      height:30px;
      display: flex;
      align-items: center;
    }
    .wallwttime{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 10px;
      //margin-bottom: 13px;
    }
  
    .idtext{
      color:#B7B9BB;
      font-size: 14px;
      font-family: ${themeGet('fonts.googleSansBold')};
      padding: 0px 0px;
      white-space: break-spaces;
      word-break: break-all;
    }
    .timetext{
      color: #898989;
      font-size: 13px;
      font-family: ${themeGet('fonts.googleSansRegular')};
      padding: 0px 0px;
    }
    .incomecolor{
      color: #529652;
      font-family: ${themeGet('fonts.googleSansMedium')};
      font-weight: inherit;
    }
  }
`;

export const StyledMywalletTableVied = styled.div`
  border: 1px solid ${themeGet('colors.tableheaderborder')};
  border-radius: 6px;
  min-height: 490px;
`;

export const StyledMywalletMobile = styled.div`
 display:none;
 ${device.TabToLg} {
  display:block;
 }

`;

export const StyledMywalletMobileHead = styled.div`
  display: flex;
  ul{
    display: flex;
    list-style: none;
    border-bottom: 1px solid #21252D;
    padding-bottom: 10px;
    width: 100%;
    overflow: auto;
    li{
      display: flex;
      border-right:1px solid #2E2E2E;
      a{
        padding:0px 18px;
        font-size: 13px;
        color:${themeGet('colors.copyrighttextcolor')};
        font-family: ${themeGet('fonts.googleSansMedium')};
        white-space: nowrap;
      }
    }
   }
`;

export const StyledMywalletMobileList = styled.div`
  display: flex;
  flex-direction: column;
  .mywalletview{
    color: #B5B5B5;;
    display: flex;
    width:100%;
    flex-direction: column;
    border-top:4px solid #14181D;
    border-bottom:1px solid #21252D;
    padding:20px 16px 30px 16px;
  }
  .balancetext{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding:8px 10px;
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    background: rgba(27, 31, 37, 0.30);
  }
  .incomtext{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding:8px 10px;
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansMedium')};
  }
  .expensestext{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding:8px 10px;
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    background: rgba(27, 31, 37, 0.30);
  }
  .balanceaftertext{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding:8px 10px;
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansMedium')};
  }
  .remarktext{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top:2px solid rgba(27, 31, 37, 0.50);
    padding: 8px 10px;
  }
  .textname{
    color: rgba(183, 185, 187, 0.50);
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
  .bettype{
    border-radius: 20px;
    padding: 0px 14px;
    color: #AB7E65;
    font-size: 12px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    background: linear-gradient(128deg, #1B1F25 0%, rgba(27, 31, 37, 0.30) 64.58%, rgba(27, 31, 37, 0.70) 97.92%);
    height:30px;
    display: flex;
    align-items: center;
  }
  .wallwttime{
    display: flex;
    justify-content: space-between;
    align-items: center;
    //margin-bottom: 13px;
  }

  .idtext{
    color:#B7B9BB;
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansBold')};
    padding: 0px 0px;
  }
  .timetext{
    color: #898989;
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    padding: 0px 0px;
  }
  .incomecolor{
    color: #529652;
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-weight: inherit;
  }
  display:none;
 ${device.TabToLg} {
  display:block ;
 }
`;

export const StyledPagination = styled.div`
 display:flex;
 justify-content: space-between;
 padding-top:20px;
 ${device.TabToLg}{
  padding: 10px 16px;
  margin: 2px;
  background: linear-gradient(180deg, rgba(38, 51, 77, 0.80) 0%, rgba(25, 33, 50, 0.48) 100%);
  box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  .pagination-bar{width: 100% !important;}
  .itempage{
    display:none !important;
  }
 }
 .itempage{
  display: flex;
  align-items: center;
  gap: 10px;
  
  p{
    color: #B5B5B5;
    font-size: 12px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    text-transform: capitalize;
  }
  select{
    border: 0px;
    background: none;
    color: #fff;
  }
 }
.pagination-bar {
  //width: 100%;
  display: flex;
  justify-content: flex-end;
  width:88%;
  position: relative;
}
.pagesize{
  position: absolute;
  left: 0px;
  top: 3px;
}
.pagination-container {
  display: flex;
  list-style-type: none;
  .pagenext{
    width: 24px;
    height: 24px;
    border: 2px solid #616972;
    border-radius: 100px !important;
    padding: 0px !important;
    min-width: 24px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .currentpage{ margin-right:15px !important;}
  .pagination-item {
    padding: 0 0px;
    //height: 32px;
    text-align: center;
    margin: auto 2px;
    color: #fff;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 24px;
    justify-content: center;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.08);
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        //content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(255, 255, 255, 0.87);
        border-top: 0.12em solid rgba(255, 255, 255, 0.87);
      }

      &.left {
        //transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        //transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(255, 255, 255, 0.43);
        border-top: 0.12em solid rgba(255, 255, 255, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

`;

export const StyledStraightBody  = styled.div`
//background:#F2F2F6;
//background:${themeGet('colors.overlaybg')};
border-radius: 0px 0px 6px 6px;
display: flex;
width: 100%;
gap:16px;
//padding:18px 17px;
.straighticon{
  position: absolute;
  z-index: 9;
  left: 16px;
  top: 11px;
  font-size: 20px !important;
  font-weight: 400;
  font-family: 'Sora', sans-serif;
  color:${themeGet('colors.NumberGameTextcolor')};

}
.straightview{
  .straighticon{
    top: 6px;
    padding-left: 0px;
  }
}
.straightview{
  display: flex;
width: 100%;
flex-direction: column;
span.active{
  color:#5170FF;
  font-weight: 600;
}
span{
  font-size: 14px;
  font-weight: 400;
  color: #232F4D;
  padding: 0px 10px 2px 4px;
}
.custominput{
  height:44px;
}
}
.rumble{ 
  display: flex;
  width: 100%;
  flex-direction: column;
span.active{
  color:#5170FF;
  font-weight: 600;
}
span{
  font-size: 14px;
  font-weight: 400;
  color: #232F4D;
  padding: 0px 10px 2px 4px;
}
.custominput{
  height:44px;
}
.straighticon{
  top: 6px;
  padding-left: 0px;
}
}
.straight{
  padding-left: 35px;
  padding-right:16px;
  color:${themeGet('colors.NumberGameTextcolor')};
  font-weight: 600;
  font-family: 'Sora', sans-serif;
  border:1.5px solid #232F4D;
  height:48px;
  //text-align: center;
  //background:#ff0000;
}
// .straightFocus{background:#ff0;}
input{
  padding: 6px 12px 6px 12px;
  border-radius: 10px !important;
  border: 1.5px solid #A0ADB5;
  background:${themeGet('colors.white')};
  font-family: 'Sora', sans-serif;
  font-weight: 400;
  color:#5C5C5C;
  font-size: 20px;
  &:focus{
    background:${themeGet('colors.white')};
  }
}
.custominput {
  border-radius: 10px;
  border: 1.5px solid #232F4D;
  background: #ffffff;
  padding: 6px 16px 6px 35px;
  height: 52px;
  font-size: 20px;
  font-family: 'Sora',sans-serif;
  font-weight: 600;
  text-align: left;
  display: flex;
  align-items: center;
  //margin: 24px 0px;
  position: relative;
  width: 100%;
}
.custominput.active{
  border: 2px solid #5170FF;
}

`;

export const StyledStraightHead  = styled.div`
// border-radius: 6px 6px 4px 4px;
// background: #1E3A8A;
//background:${themeGet('colors.largegamecardborder')};
//height: 24px;
//padding: 4px;
display: flex;
justify-content: center;
align-items: center;
p{
  font-size: 13px;
  letter-spacing: 0.042px;
  font-weight: 500;
  line-height: 20px;
  color:${themeGet('colors.white')};
  font-family: 'Sora', sans-serif;
}
`;

export const StyledStraightView = styled.div`
padding: 10px;
background:${themeGet('colors.white')};
margin: 12px;
border-radius: 20px;
border: 2px solid #DCE1E8;

`;

export const StyledStraightInput  = styled.div`
padding:6px 4px 4px 4px;
background: linear-gradient(270deg, #A0ADB5 0.03%, #C3D0D8 99.98%);
border-radius: 12px;
//background: #D5D7DD;
`;

export const StyledGameNumber = styled.div`
display: flex;
flex-direction: column;
//background: #D5D7DD;
ul{
  list-style: none;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  gap: 10px;
  padding:0px 10px 0px 10px;
  margin-bottom: 20px;
  li{
      display: flex;
      width: 100%;
      height:100%;
      //height: 80px;
      // border-radius: 20px;
      // background:${themeGet('colors.white')};
      // box-shadow: 0 10px rgba(160, 172, 192, 0.50);
         margin-bottom: 10px;
        a{
          display: flex;
          width: 100%;
          height: 100%;
          text-align: center;
          align-items: center;
          justify-content: center;
          font-size: 36px;
          font-family: 'Sora', sans-serif;
          color:#313131;
          background:${themeGet('colors.white')};
          box-shadow: 0 10px rgba(160, 172, 192, 0.50);
          border-radius: 20px;
          //margin-bottom: 10px;
          // background: linear-gradient(194deg, #C2C9D6 0%, rgba(194, 201, 214, 0.78) 107.41%);
          // background-clip: text;
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
        }
    // ${device.MobSLSm}{
    //   width: 80px;
    //   height: 80px;
    //   }
    // ${device.MobToSMM}{
    //   width: 75px;
    //   height: 75px;
    // }
    // ${device.MobToHMM}{
      
    //   width: 68px;
    //   height: 68px;
    // }
   }
   li:active a {
    box-shadow: 0 5px rgba(160, 172, 192, 0.50);
    transform: translateY(5px);
   }
   .addqueue a{
    background: #31A354;
    box-shadow: 0 10px #006D2C;
    display: flex;
    flex-direction: column;
    a{
      flex-direction: column;
      color:${themeGet('colors.white')};
      background-clip:inherit;
      -webkit-text-fill-color: currentColor;
      background: none;
    }
    p{
      font-size: 13px;
      font-weight: 700;
      font-family: 'Sora', sans-serif;
      color:${themeGet('colors.white')};
    }
    h6{
      font-size: 16px;
      font-weight: 700;
      font-family: 'Sora', sans-serif;
      color:${themeGet('colors.white')};
    }
   }
   .removeback a{
    background: #FB6A4A;
    box-shadow: 0 10px #D03B35;
   }
   li.removeback a:active{
    background: #FB6A4A;
   }
   li.removeback a:active {
    background: #FB6A4A;
    box-shadow: 0 5px #D03B35;
    transform: translateY(5px);
   }
   li.addqueue a:active {
    box-shadow: 0 5px #006D2C;
    transform: translateY(5px);
   }
   
}
.disabled {
  pointer-events: none;
  opacity: .4;
}


`;

export const StyledEventTime = styled.div`
padding:10px 10px 10px 10px;
display: flex;
flex-direction: row;
align-items: center;
p{
  color:#232F4D;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Sora', sans-serif;
  padding-right:16px;
  margin-left: 6px;
}
ul{
  display: flex;
  gap: 10px;
  //justify-content: space-between;
  width: 100%;
  li{
    padding: 12px 14px;
    border-radius: 10px;
    height:32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    border: 1px solid #C9CDD0;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    a{
      color:${themeGet('colors.NumberGameTextcolor')};;
      font-size: 15px;
      font-family: 'Sora', sans-serif;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      span{
        font-weight: 400;
      }
    }

  }
  li.active{
    border-radius: 16px;
    border: 1px solid #395CF1;
    background: linear-gradient(270deg, #3A5DF4 8.2%, #5471F8 100%);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    
    a{
      color:${themeGet('colors.white')};
    }
  }
}
`;

export const StyledGameAmountView = styled.div`
width: 100%;
//height: 180px;
padding:0px 10px;
display: flex;

`;

export const StyledEnterBetAmount = styled.div`
  width: 100%;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  p{
    color:${themeGet('colors.NumberGameTextcolor')};
    padding:10px 0px 6px 0px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Sora', sans-serif;
    margin-left: 6px;
  }
  
`;

export const StyledQueueBtn = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0px 30px;
  .addqueuebtn{
    border-radius: 16px;
    border: 2px solid #4165FF;
    background: #4165FF;
    height:48px;
    border-radius: 10px;
    display: flex;
    color:${themeGet('colors.white')};
    font-size: 16px;
    font-weight: 600;
    font-family: 'Sora', sans-serif;
    display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
  }
  .addqueuebtn.disabled{
    pointer-events: none;
    border-radius: 10px;
    border: 2px solid #A0ACC0;
    background: linear-gradient(86deg, #A0ACC0 3.24%, #B5C0D2 94.68%);
  }
`;

export const StyledBetAmountView = styled.div`
border-radius: 20px;
border: 2px solid #DCE1E8;
background:${themeGet('colors.white')};
display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
.custombtn{
  border-radius: 8px;
  border: 1px solid #3D5FF5;
  background: #ffffff;
  padding: 0px 6px;
  font-size: 15px;
  color: #3D5FF5;
  font-weight: 600;
  font-family: 'Sora',sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  line-height: 25px;
  margin-top: 8px;
  }
`;

export const StyledGameAmount = styled.div`
  border-radius: 20px;
  background:${themeGet('colors.white')};
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top:15px;
  padding-bottom:4px;
  border: 2px solid #DCE1E8;
`;

export const StyledCustomView = styled.div`
display: flex;
width: 100%;
ul{
  list-style: none;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 12px;
  display: grid;
  gap: 10px;
  margin-bottom: 6px;
  li{
    display: flex;
    width: 100%;
    height:100%;
    a{
      display: flex;
      width: 100%;
      height: 44px;
      text-align: center;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-family: 'Sora', sans-serif;
      color:${themeGet('colors.NumberGameTextcolor')};
      border-radius: 17.162px;
      border: 2px dashed #BDC6D5;
      background: #E4E9F3;
      padding: 12px 0px;
      line-height: 100%;
      .straighticon{
        margin-right:4px;
      }
    }
  }
}

`;
export const StyledGameInfo = styled.div`
display: flex;
padding:6px 19px 6px 10px;
align-items: center;
justify-content: space-between;
p{
  //color:${themeGet('colors.eventnumberdefaulttxtcolor')};
  color:${themeGet('colors.NumberGameTextcolor')};;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Sora', sans-serif;
  margin-left: 6px;
}
`;

export const StyledGameInfopop = styled.div`
display: flex;
padding:10px 10px;
align-items: center;
justify-content: space-between;
background:${themeGet('colors.white')};
width: 315px;
padding: 16px;
border-radius: 20px;
border: 1px solid rgba(255, 255, 255, 0.25);
backdrop-filter: blur(20px);
flex-direction: column;
.changetext{
  text-align: center;
  padding-bottom: 0px;
}
h4{
  font-size: 20px;
  font-weight: 600;
  font-family: 'Sora', sans-serif;
  color:${themeGet('colors.black')};
  //border-bottom:1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  margin-bottom: 0px;
  color:${themeGet('colors.NumberGameTextcolor')};
  text-align: center;
 
}
p{
  color: #73849A;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Sora', sans-serif;
  padding-bottom:20px;
  //text-align: center;
}
.loginform{
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  .phonecode{
    position: absolute;
    top: 11px;
    left: 8px;
    font-size: 14px;
    font-family: 'Sora', sans-serif;
    font-weight: 400;
  }
  input{
    border-radius: 10px !important;
    border: 1px solid #A1AEB6;
    background: rgba(239, 246, 255, 0.80);
    padding-left: 50px !important;
  }
  input.error{
    border:1px solid #ff7c54;
  }
  p.error{
    font-size: 11px;
    position: absolute;
    right: 1px;
    top: 72px;
    color: #ff7c54;
  }
}
.loginform{
p.error.erroephonenumber{
  top: 41px !important;
}
}
.buttonview{
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top:20px;
  gap: 20px;
  .confirmbtn{
    border-radius: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    background: linear-gradient(270deg, #3B60FE 0.03%, #5573FE 99.98%);
    font-size: 16px;
    font-weight: 600;
    padding: 10px 10px;
    width:145px;
    height:46px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:${themeGet('colors.white')};
  }
  .closebtn{
    border-radius: 16px;
    border: 1px solid #1D4ED8;
    color:${themeGet('colors.topheaderbackground')};
    font-size: 16px;
    font-weight: 600;
    padding: 10px 10px;
    width:145px;
    height:46px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.loginform label {
  font-family: 'Sora', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color:${themeGet('colors.NumberGameTextcolor')}; ;
  letter-spacing: 0.042px;
  line-height: 20px;
  margin-bottom: 10px;
  display: flex;
}
.closeicon{
  position: relative;
  height: 20px;
  width: 20px;
  display: flex;
  right: 0px;
  width: 100%;
  svg{
    position: absolute;
    right: 0px;
    width: 20px;
    top: -7px;
  }
}
.border{height:1px; background:rgba(0, 0, 0, 0.15); width:100%; margin-bottom: 8px;}
`;
export const StyledNumberText = styled.div`
display: flex;
gap: 30px;
margin-top: 10px;
width: 100%;
align-items: center;
justify-content: center;
p{
  font-size: 12px;
  font-family: 'Sora', sans-serif;
  font-weight: 400;
  color: ${themeGet('colors.NumberGameTextcolor')};;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
`;

export const StyledGameInputText = styled.div`
position: relative;
display: flex;
gap: 10px;
.textposition{
  display: flex;
  position: relative;
  svg{
    width: 96px;
    height: 96px;
  }
}
// a{ 
//   display: flex;
//   position: relative;
//   svg{
//     width: 96px;
//     height: 96px;
//   }
// }
.double_digit{
  svg{
    width:90px;
    height:90px;
  }
}
p{
  font-size: 32px;
  color: #000;
  font-family: 'Sora', sans-serif;
  font-weight: 700;
  z-index: 9;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s;
  line-height: 28px;
}

`;

export const StyledGameRow = styled.div`
display: flex;
width: 100%;
align-items: center;
justify-content: center;
flex-direction: column;
.popevent{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
a{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.edittext{
  color: #628ED9;
  font-size: 13px;
  font-weight: 400;
  font-family: 'Sora', sans-serif;
  margin-top: 5px;
}
`;

export const StyledPopupView = styled.div`
background: rgba(0, 0, 0, 0.85);
backdrop-filter: blur(6px);
position: fixed;
  height: 100%;
  z-index: 9999;
  bottom: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left:0px;
`;
