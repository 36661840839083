import styled, {device, themeGet } from '@/shared/styled';


export const StyledAgeContainer = styled.div`
  display: block;
`;

export const StyledAgeOverLay = styled.div`
  // background: hsla(0, 0%, 5%, 0.7);
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(6px);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  z-index: 9991;
`;

export const StyledOverlayContainer = styled.div`
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 16px;
  z-index: 9999;
  height: calc(100% - 60px);
  width: calc(100% - 48px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: #DAE0EB;
  backdrop-filter: blur(20px);
  transition: all 0.3s;
  &.popupview-multiple{
    height:auto;
  }
`;

export const StyledOverlayHead = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  h4{
    font-size: 16px;
    font-weight: 500;
    color:${themeGet('colors.NumberGameTextcolor')};
  }

`;

export const StyledHead = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  margin-top: 10px;
  a{
    position: absolute;
    top: -15px;
    right: 0px;
  }
`;

export const StyledEventTime = styled.div`
display: flex;
flex-direction: column;
position: relative;
.noevent{
  color: #f00;
  position: absolute;
  top: -44px;
  left: 55px;
  font-size: 11px;
}
p{
  color: #00295F;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Sora', sans-serif;
  padding:8px 0px 0px 0px;
}
ul{
  display: flex;
  gap: 4px;
  width: 100%;
  overflow: auto;
  padding-bottom: 8px;
  li{
    height:35px;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    border: 1px solid #C9CDD0;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    padding: 12px 16px;
    a{
      color:${themeGet('colors.NumberGameTextcolor')};;
      font-size: 15px;
      font-family: 'Sora', sans-serif;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        font-weight: 400;
        line-height: 20px;
        margin-left: 4px;
      }
    }

  }
  li.active{
    border-radius: 16px;
    border: 1px solid #395CF1;
    background: linear-gradient(270deg, #3A5DF4 8.2%, #5471F8 100%);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    a{
      color: ${themeGet('colors.white')};
    }
  }
  }
`;

export const StyledOverlayBody = styled.div`
height: calc(100% - 110px);
text-align: center;
overflow-y: auto;
  ul{
    list-style: none;
    width: 100%;
    grid-template-columns: repeat(5, 1fr);
    display: grid;
    gap: 11px;
    padding-top:10px;
    margin-bottom: 20px;
    li{
        display: flex;
        width: 100%;
        height:100%;
        margin-bottom: 10px;
          a{
            display: flex;
            width: 100%;
            height: 100%;
            text-align: center;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            font-family: 'Sora', sans-serif;
            color: #232F4D;
            background: #ffffff;
            box-shadow: 0 4px rgba(160, 172, 192, 0.50);
            border-radius: 14px;
            // ${device.MobSLSm}{
            //   width: 42.33px;
            //   height: 48.359px;
            // }
          }
      // ${device.MobSLSm}{
      //   width: 80px;
      //   height: 80px;
      //   }
      // ${device.MobToSMM}{
      //   width: 75px;
      //   height: 75px;
      // }
      // ${device.MobToHMM}{
        
      //   width: 68px;
      //   height: 68px;
      // }
     }
     li:active a {
      //background:#DC2424;
      box-shadow: 0 4px rgba(160, 172, 192, 0.50);
      //color:${themeGet('colors.white')};
      transform: translateY(5px);
     }
     li.active a {
      background:#DC2424;
      box-shadow: 0 5px #980A0A;
      color:${themeGet('colors.white')};
      //transform: translateY(5px);
     }
     .addqueue a{
      background: #31A354;
      box-shadow: 0 10px #006D2C;
      display: flex;
      flex-direction: column;
      a{
        flex-direction: column;
        color:${themeGet('colors.white')};
        background-clip:inherit;
        -webkit-text-fill-color: currentColor;
        background: none;
      }
      p{
        font-size: 13px;
        font-weight: 700;
        font-family: 'Sora', sans-serif;
        color:${themeGet('colors.white')};
      }
      h6{
        font-size: 16px;
        font-weight: 700;
        font-family: 'Sora', sans-serif;
        color:${themeGet('colors.white')};
      }
     }
     .removeback a{
      background: #FB6A4A;
      box-shadow: 0 4px #D03B35;
     }
     li.removeback a:active {
      box-shadow: 0 5px #D03B35;
      transform: translateY(5px);
     }
     li.addqueue a:active {
      box-shadow: 0 5px #006D2C;
      transform: translateY(5px);
     }
     
  }

  .numbersgame{
    grid-template-columns: repeat(4, 1fr);
    padding-top: 24px;
    gap:14px;
  }
  .confirmbtn{
    border-radius: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    background: linear-gradient(270deg, #3B60FE 0.03%, #5573FE 99.98%);
    color:${themeGet('colors.white')};
    padding: 16px 10px;
    height:56px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Sora', sans-serif;
    margin-top:30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.noeventview{
    padding-bottom:15px;
    h4{
      font-size: 20px;
      font-weight: 600;
      color: #000;
      padding-top: 10px;
      padding-bottom: 8px;
      
    }
    p{
      color: rgba(0, 0, 0, 0.45);
      font-size: 15px;
      font-weight: 400;
      font-family: 'Sora', sans-serif;
      line-height: 20px;
      strong{
        font-size: 16px;
        color: #212121;
        font-weight: 700;
      }
    }
  }
`;

export const StyledOverlatTextView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    circle {
      fill: ${themeGet('colors.mainborder')};
    }

    path:last-child {
      fill: ${themeGet('colors.white')};
    }
  }
  h4 {
    font-size: 24px;
    margin-bottom: 20px;
    color: ${themeGet('colors.white')};
    font-family: ${themeGet('fonts.googleSansMedium')};
  }
  p {
    color: #cecece;
    font-size: 17px;
    margin-top: 10px;
    margin-bottom: 0px;
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
  .agree {
    font-size: 17px;
    margin-bottom: 25px;
    margin-top: 0px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    a {
      color: ${themeGet('colors.mainbackground')};
      text-decoration: underline;
    }
  }

  .yeare_button {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 16px;
    border: 0px;
    gap: 4px;
    height: 42px;
    padding: 0px 24px;
    color: ${themeGet('colors.pagefontcolor')};
    border-radius: 6px;
    &:hover {
      background: ${themeGet('colors.mainborder')};
      font-size: 16px;
      border: 0px;
      height: 42px;
    }
  }

  .exit_button {
    background: none;
    border: 1px solid ${themeGet('colors.mainborder')};
    color: ${themeGet('colors.btnfontcolor')};
    display: flex;
    align-items: center;
    gap: 4px;
    height: 42px;
    font-size: 16px;
    font-family: ${themeGet('fonts.googleSansBold')};
    padding: 0px 24px;
    border-radius: 6px;
    &:hover {
      background: none;
      border: 1.5px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
      height: 42px;
      font-size: 16px;
    }
    &:active {
      background: none;
      border: 1px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
    }
  }
`;

export const StyledAgeButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
`;
export const StyledGameInputText = styled.div`
position: relative;
display: flex;
gap: 10px;
a{ 
  display: flex;
  position: relative;
  svg{
    width: 78px;
    height: 78px;
    ${device.MobToSLL}{
      width: 65px;
      height: 65px;
    }
  }
}
p{
  font-size: 24px;
  color: #000;
  font-family: 'Sora', sans-serif;
  font-weight: 700;
  z-index: 9;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s;
}

`;

export const StyledGameAmount = styled.div`
  border-radius: 20px;
  background:${themeGet('colors.white')};
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 padding:25px 16px;
  border: 2px solid #DCE1E8;
  margin-top:10px;
`;

export const StyledCustomView = styled.div`
display: flex;
width: 100%;
ul{
  list-style: none;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 12px;
  display: grid;
  gap: 10px;
  margin-bottom: 6px;
  li{
    display: flex;
    width: 100%;
    height:100%;
    a{
      display: flex;
      width: 100%;
      height: 40px;
      text-align: center;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-family: 'Sora', sans-serif;
      color:${themeGet('colors.NumberGameTextcolor')};
      box-shadow: 0 4px rgba(160, 172, 192, 0.50);
      border-radius: 17.162px;
      background: #E5E5F1;
      padding: 10px 0px;
      line-height: 100%;
    }
  }
  li:active a {
    box-shadow: 0 4px rgba(160, 172, 192, 0.50);
    transform: translateY(5px);
   }
}

`;
export const StyledGameInfo = styled.div`
display: flex;
padding:6px 19px 6px 10px;
align-items: center;
justify-content: space-between;
p{
  //color:${themeGet('colors.eventnumberdefaulttxtcolor')};
  color:${themeGet('colors.NumberGameTextcolor')};;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Sora', sans-serif;
  margin-left: 6px;
}
`;

export const StyledGameRow = styled.div`
display: flex;
width: 100%;
align-items: center;
justify-content: center;
flex-direction: column;
.edittext{
  color: #628ED9;
  font-size: 13px;
  font-weight: 400;
  font-family: 'Sora', sans-serif;
  margin-top: 5px;
}
`;

export const StyledGameAmountView = styled.div`
width: 100%;
//height: 180px;
padding:0px 0px;
display: flex;
flex-direction: column;
p.custom-error{
  color: #ff0000;
  font-size: 13px;
  position: absolute;
  bottom: -22px;
  border: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.custom-error{
  border:2px solid #ff0000;
  position: relative;
}
.numbersgame{
  grid-template-columns: repeat(4, 1fr);
}

`;