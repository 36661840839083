import React, { useState } from 'react';
import {
  StyledSupportContainerFluid,
  StyledSupportHead,
  StyledSupportContainer,
  StyledSupportContact,
  StyledAccordionContainer,
  StyledAccordionContent,
} from './style';
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/esm/Accordion';
import { ContactCopyIconSvg, HeaderBackIconSvg } from '@/shared/svg';
import Footer from '@/layout/footer';

interface childProps {
  queueList: any;
  closeQueuePopup: Function;
  clearQueue: Function;
  eventName: any;
  setParentQueuedData: Function;
  setParentCartTotal: Function;
}

const Support = (props: childProps) => {
  const [support, setSupport] = useState('(+63) 9876543210');
  const [textToCopy, setTextToCopy] = useState('');
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    // Copy the text to the clipboard
    setCopied(true);
    performCopyAction();
  };

  const performCopyAction = () => {
    // Implement the copy action here
    const textToCopy = support; // Replace with your desired text

    // Create a temporary textarea to copy the text
    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;
    document.body.appendChild(textarea);

    // Select and copy the text
    textarea.select();
    document.execCommand('copy');

    // Clean up and remove the temporary textarea
    document.body.removeChild(textarea);

    // Optionally, you can show a notification or update the UI to indicate that the copy was successful
    //alert('Text copied to clipboard!');
  };

  const enableQueue = () => {
  }

  return (
    <>
      <StyledSupportContainerFluid>
        <StyledSupportHead>
          <Link to='/dashboard'>
            <HeaderBackIconSvg />
          </Link>
          <strong>Support</strong>
        </StyledSupportHead>
        <StyledSupportContainer>
          <h6>Contact our team</h6>
          <p>The support team is always available to help.</p>
        </StyledSupportContainer>
        <StyledSupportContact>
          <label>Phone Number</label>
          <p>
            <span>(+63) 9876543210</span>
            <button onClick={handleCopy}>
              <span className={`${copied ? 'active' : ''}`}>
                <ContactCopyIconSvg />
              </span>
            </button>
          </p>
        </StyledSupportContact>
        <StyledAccordionContainer>
          <h3>Instructions</h3>
          <StyledAccordionContent>
            <Accordion>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Agent Login</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      The agent is provided with a unique ID and a daily
                      changing password.
                    </li>
                    <li>
                      The password is valid until midnight each day. After that,
                      the agent should request a new password from the PCSO
                      Number game Super admin.
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='1'>
                <Accordion.Header>Agent Wallet and Balance</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      The admin is given a specific amount in their wallet,
                      which is monitored on the Point Of Sale (POS) and Balance
                    </li>
                    <li>
                      After Agent achieves his/her daily target can reach to the
                      customer support Representative for refill
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='2'>
                <Accordion.Header>Picks</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      The Number game app offers two options for picking
                      numbers: Lucky Pick and Swertres.
                    </li>
                    <li>
                      Lucky Pick enables the customer to choose any number
                      between 1-99
                    </li>
                    <li>
                      Sweaters enables the customer to choose any 3 numbers from
                      0-9
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='3'>
                <Accordion.Header>Placing Bets</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      Agent facilitates the customer in choosing the pick,
                      placing bets and choosing the bet amount
                    </li>
                    <li>
                      The agent can place bets for any amount with a min. of 5
                      pesos and multiples of 5
                    </li>
                    <li>
                      Agent co-ordinates with the customer to place bets and
                      make purchases for any event on a given day
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='4'>
                <Accordion.Header>Ticket Authorization</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      The Agent must ensure they obtain the customer's Gcash
                      registered phone number for generating the ticket and
                      transactions.
                    </li>
                    <li>
                      If requested by the customer, the agent has the option to
                      send an invoice copy of the purchase.
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='5'>
                <Accordion.Header>Customer Support</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li>
                      A dedicated customer help line is always active for Agent
                      to contact support team for assistance or
                      clarification.
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </StyledAccordionContent>
        </StyledAccordionContainer>
      </StyledSupportContainerFluid>
      <Footer
        placeBet={undefined}
        enableQueue={enableQueue}
        queueList={undefined}
        enableQueuePopup={undefined}
      />
    </>
  );
};

export default Support;
