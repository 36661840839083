import styled, { themeGet } from '@/shared/styled';

export const StyledLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
    background: rgba(0, 0, 0, 0.85);
    z-index: 9999999;
    height: 100%;
    width: 100%;
    top: 0px;
`;

export const StyledLoadView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  //border: 2px solid ${themeGet('colors.mainborder')};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 10px 15px;
  text-align: center;
  //background: #000000;
  z-index: 99999;
`;

export const StyledLoadLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 30px;
  }
`;

export const StyledLoadGif = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    width: 20px;
    position: absolute;
  }
  h6 {
    font-size: 16px;
    color: #041e3b;
    font-family: ${themeGet('fonts.googleSansMedium')};
  }
  .custom-loader {
    width:75px;
    height:75px;
    border-radius:50%;
    position: relative;
    background:conic-gradient(#0000 10%,#5573FE);
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation:s3 1s infinite linear;
  }
  @keyframes s3 {to{transform: rotate(1turn)}}
`;
