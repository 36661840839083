import styled, { device, themeGet } from '@/shared/styled';

export const StyledDashboardHead = styled.div`
z-index: 20;
top: 0px;
left: 0px;
right: 0px;
width: 100%;
position: sticky;
height: 58px;
background: ${themeGet('colors.topheaderbackground')};
border-bottom: 1px solid rgba(255, 255, 255, 0.15);
font-size: 24px;
font-weight: 600;
font-family: 'Sora', sans-serif;
color:${themeGet('colors.white')};
text-align: center;
display: flex;
align-items: center;
justify-content: center;
.backarrow{
  position: absolute;
  left: 16px;
  top: 10px;
}
`;

export const StyledTicketContainerFluid = styled.div`
  //background: ${themeGet('colors.bodybackground')};
  //background: linear-gradient(180deg, #F3F3FC 0%, #DFDFEC 91.6%);
  //box-shadow: 0px -20px 60px 0px rgba(0, 0, 0, 0.15);
  //min-height: calc(100vh - 135px);
  //padding: 0px 10px 20px 10px;border-radius: 26px 26px 0px 0px;
  background: linear-gradient(180deg, #F3F3FC 0%, #DFDFEC 91.6%);
  // box-shadow: 0px -20px 60px 0px rgba(0, 0, 0, 0.15);
  //margin-top: -16px;
  position: relative;
  z-index: 999;
  display: flex;
  padding: 10px 16px 30px;
  z-index: 999;
  width: 100%;  
  position: absolute;
  top: 64px;
  //height: calc(100% - 135px);
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  ${device.xlarge} {
    // float: inherit;
    // margin: 0px auto;
  }
  .closeicon{
    position: relative;
    height: 20px;
    display: flex;
    right: 0px;
    width: 100%;
    svg{
      position: absolute;
      right:14px;
      width: 20px;
      top: -5px;
      height: 20px;
    }
  }
`;

export const StyledTicketContainer = styled.div`
  width: 100%;
  background: ${themeGet('colors.white')};
  border-radius: 20px;
  border: 2px solid #C7C7DA;
  margin-bottom:20px;
  //overflow: hidden;
`;

export const StyledTicketHead = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: row;
  padding: 0px 16px;
  align-items: center;
  margin-bottom: 16px;
  h4{
    color:${themeGet('colors.NumberGameTextcolor')};
    font-size: 20px;
    font-weight: 600;
    font-family: 'Sora',sans-serif;
    line-height: 20px;
  }
  .closeicon{
    height: 24px;
    display: flex;
    left: 0px;
    top: 0px;
    width: 24px;
    position: absolute;
    svg{
      width: 24px;
      height: 24px;
      top: 0px;
      right: 0px;
      path{
        fill: #000;
      }
    }
  }
`;

export const StyledTicketHeader = styled.div`
  width:100%;
  padding: 14px 16px 0px 16px;
  ul{
    list-style: none;
    width: 100%;
    //border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    li{
      width: 100%;
      margin-bottom: 5px;
      display: flex;
      span{
        color: #455B6F;
        font-family: 'Sora', sans-serif;
        font-size: 13px;
        font-weight: 300;
        line-height: 15px;
        //flex: 1;
      }
      strong{
        color: #232F4D;
        text-align: right;
        font-family: 'Sora', sans-serif;
        font-size: 13px;
        font-weight: 600;
        line-height: 15px;
        flex: 1;
        white-space: nowrap;
      }
    }
  }
`;

export const StyledTicketCombination = styled.div`
  width:100%;
  padding: 6px 20px 30px 20px;
  border-top: 2px dashed #CACADC;
  ul{
    list-style: none;
    width: 100%;
    //max-height: 105px;
    //overflow: auto;
    li{
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      position: relative;
      span{
        color: ${themeGet('colors.black')};
        font-family: 'Sora',sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        flex: 1;
        letter-spacing: 1.6px;
      }
      .bettype{
        position: absolute;
        bottom: -14px;
        left: 0px;
        font-size: 10px;
        color: #F54646;
      }
      .header_col{
        font-size: 13px;
        font-weight: 400;
        letter-spacing: initial;
        color: ${themeGet('colors.black')};
      }
      p{
        color: ${themeGet('colors.black')};
        text-align: right;
        font-family: 'Sora',sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.048px;
        small{
          font-size: 16px;
          font-weight:400;
        }
        b{
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    .header_row{
      margin-bottom: 6px;
      position: sticky;
      top: 0px;
      z-index: 1;
      background: #fff;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
export const StyledTicketMiddle = styled.div`
position: relative;
width: 100%;
display: block;
z-index: 2;
span {
  position: absolute;
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
  top: 0;
  transform: translateY(-50%);
  background: #ededf8;
}

span:nth-child(1) {
  width: 20px;
  height: 20px;
  left: -10px;
}
span:nth-child(2) {
  width: 20px;
  height: 20px;
  right: -10px;
}
// span:nth-child(3) {
//   left: 40px;
// }
// span:nth-child(4) {
//   left: 80px;
// }
// span:nth-child(5) {
//   left: 120px;
// }
// span:nth-child(6) {
//   left: 160px;
// }
// span:nth-child(7) {
//   left: 200px;
// }
// span:nth-child(8) {
//   left: 240px;
// }
// span:nth-child(9) {
//   left: 280px;
// }
// span:nth-child(10) {
//   left: 320px;
// }
// span:nth-child(11) {
//   left: 360px;
// }
${device.MobToSLL}{
  span:nth-child(10) {display:none;}
  span:nth-child(11) {display:none;}
}
`;
export const StyledTicketTransaction = styled.div`
  width:100%;
  padding: 0px 20px 12px 16px;
  background: ${themeGet('colors.white')};
  border-radius: 0px 0px 20px 20px;

  h3{
    text-align: center;
    font-family: 'Sora',sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 15px;
    white-space: nowrap;
    color: #232F4D;
    margin-top: 6px;
  }
  .qrcode_info{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    svg{
      height: 160px;
    }
  }
  ul{
    width: 100%;
    list-style: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 6px;
    li{
      margin-bottom:5px;
      display: flex;
      span{
        color: #455B6F;
        font-family: 'Sora',sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        flex: 1;
      }
      strong{
        color: #232F4D;
        text-align: right;
        font-family: 'Sora',sans-serif;
        font-size: 13px;
        font-weight: 600;
        line-height: 15px; 
      }
    }
  }
  p{
    display: inline-block;
    //margin-right: 12px;
    ${device.MobToMmx} {
      &:last-child{
        margin-right: 0px;
      }
    }
    ${device.MobToSmx} {
      &:last-child{
        margin-right: 0px;
      }
    }
    span{
      color: ${themeGet('colors.black')};
      font-family: 'Sora',sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-left: 5px;
      ${device.MobToMmx} {
        font-size: 12px;
        line-height: 18px;
      }
      ${device.MobToSmx} {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .ticketinformation{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
`;