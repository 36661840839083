import React, { useEffect, useState } from 'react';
import {
  StyledTicketContainerFluid,
  StyledTicketContainer,
  StyledTicketHead,
  StyledTicketHeader,
  StyledTicketCombination,
  StyledTicketTransaction,
  StyledTicketMiddle,
  StyledDashboardHead,
} from './style';
import { useAppSelector } from '@/hooks/useAppSelector';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import { TransTickCircleSvg, HeaderBackIconSvg, CloseIconSvg } from '@/shared/svg';
import QRCode from 'react-qr-code';

interface childProps {
  responseData: any;
  phoneNumer: any;
  eventName: any;
  groupCodeData: any;
  closeTicketPopup: any;
  closeQueuePopup: any;
}

const Ticket = (props: childProps) => {
  const { userInfo } = useAppSelector(state => state.auth);
  const [ticketData, setTicketData] = useState([]);

  const getBettingHistoryData = async () => {
    setTicketData(props.responseData);
  };

  const closeTicketPopup = async () => {
    props.closeTicketPopup();
    props.closeQueuePopup();
  }

  let currentTotal = props.responseData.reduce(function(prev:number, current:any) {
    return prev + +current.betAmount
}, 0);




  useEffect(() => {
    getBettingHistoryData();
  }, [props.responseData]);

  return (
    <>
      <StyledTicketContainerFluid>
        <StyledTicketHead>
          <Link className='closeicon' to='' onClick={() => closeTicketPopup()}>
              <HeaderBackIconSvg/>
          </Link>
          <h4>Tickets</h4>
        </StyledTicketHead>
        <StyledTicketContainer>
          <StyledTicketHeader>
            <ul>
              <li>
                <span>Event Details:</span>
                <strong>
                  {moment(props.eventName.slice(0, 2), 'HH').format('ha')}
                  {/* <strong>{moment().format('lll')}</strong> */}
                </strong>
              </li>
              <li>
                <span>Ticket Date:</span>
                <strong>{moment().format('lll')}</strong>
              </li>
              <li>
                <span>Contact Number:</span>
                <strong>{ticketData.length > 0 && props.groupCodeData.phoneNumber}</strong>
              </li>
            </ul>
          </StyledTicketHeader>
          
          <StyledTicketTransaction>
          <ul>
              <li>
                <span>Total Transactions</span>
                <strong>{ticketData.length}</strong>
              </li>
              <li>
                <span>Total Amount</span>
                <strong><small>₱</small> {ticketData.length > 0 &&  currentTotal}</strong>
              </li>
            </ul>
            <h3>TICKET NO. {props.groupCodeData.groupCode}</h3>
            <div className='qrcode_info'>
              
              <QRCode
                style={{ height: '160', maxWidth: '100%', width: '160' }}
                value={props.groupCodeData.groupCode}
                viewBox={`0 0 160 160`}
              />
            </div>
            
            {/* <div className='ticketinformation'>
              <p>
                <TransTickCircleSvg />
                <span>Ticket Confirmation</span>
              </p>
              <p>
                <TransTickCircleSvg />
                <span>SMS Confirmation</span>
              </p>
            </div> */}
          </StyledTicketTransaction>
          <StyledTicketMiddle>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </StyledTicketMiddle>
          <StyledTicketCombination>
            <ul>
              <li className='header_row'>
                <span className='header_col'>Combination</span>
                <strong className='header_col'>Amount</strong>
              </li>
              {ticketData.length > 0 &&
                ticketData.map((item: any, index: any) => {
                  return (
                    <li>
                      <span>{item.runnerQueue} 
                      <span className='bettype'>{item.betType}</span>
                      </span>
                      <p><small>₱</small> <b>{item.betAmount}</b></p>
                    </li>
                  );
                })}
            </ul>
          </StyledTicketCombination>
          {/* <StyledTicketTransaction>
            <h3>TICKET NO. {props.groupCodeData.groupCode}</h3>
            <div className='qrcode_info'>
              <QRCode
                style={{ height: '160', maxWidth: '100%', width: '160' }}
                value={props.groupCodeData.groupCode}
                viewBox={`0 0 160 160`}
              />
            </div>
            <ul>
              <li>
                <span>Total Transactions</span>
                <strong>{ticketData.length}</strong>
              </li>
              <li>
                <span>Total Amount</span>
                <strong><small>₱</small> {ticketData.length > 0 &&  currentTotal}</strong>
              </li>
            </ul>
            <div className='ticketinformation'>
              <p>
                <TransTickCircleSvg />
                <span>Ticket Confirmation</span>
              </p>
              <p>
                <TransTickCircleSvg />
                <span>SMS Confirmation</span>
              </p>
            </div>
          </StyledTicketTransaction> */}
        </StyledTicketContainer>
      </StyledTicketContainerFluid>
    </>
  );
};

export default Ticket;
