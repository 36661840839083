import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IModule {
  cartInfo: any;
  cartTotal: number;
  gameId: number;
}

const initialState: IModule = {
    cartInfo: [],
    cartTotal:0,
    gameId: 0
};

export const cartSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    setCartInfo: (state, action) => {
      state.cartInfo = action.payload;
    },
    setCartTotal:(state, action)=>{
      state.cartTotal = action.payload;
    },
    setGameId:(state, action)=>{
        state.gameId = action.payload;
    }
  },
});
export const { setCartInfo, setCartTotal, setGameId } = cartSlice.actions;
export default cartSlice.reducer;
