import { forwardRef } from 'react';
import classnames from 'clsx';
import { StyledInput } from './style';
import Feedback from '@/components/forms/form-elements/feedback';
import { InputProps } from '@/components/forms/form-elements/types';
import React from 'react';

interface IProps extends InputProps {
  type?: string;
  maxLength?: number;
}

const Input = forwardRef<HTMLInputElement, IProps>(
  (
    {
      className,
      type,
      disabled,
      state,
      feedbackText,
      id,
      name,
      onChange,
      onClick,
      onBlur,
      onFocus,
      value,
      readonly,
      showState,
      showErrorOnly,
      width,
      height,
      maxLength,
      customStyle,
      ...restProps
    },
    ref
  ) => {
    return (
      <>
        <StyledInput
          type={type}
          disabled={disabled}
          ref={ref}
          className={classnames(className, 'form-control')}
          id={id}
          name={name}
          autoComplete='off'
          onChange={onChange}
          onClick={onClick}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          maxLength={maxLength}
          readOnly={readonly}
          $state={state}
          $showState={showState}
          $showErrorOnly={showErrorOnly}
          $width={width}
          $height={height}
          $customStyle={customStyle}
          {...restProps}
        />
        {feedbackText && showState && (
          <Feedback
            state={state}
            showState={showState}
            showErrorOnly={showErrorOnly}
          >
            {feedbackText}
          </Feedback>
        )}
      </>
    );
  }
);

Input.displayName = 'Input';

Input.defaultProps = {
  type: 'text',
  showErrorOnly: true,
};

export default Input;
