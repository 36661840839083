import { Navigate } from 'react-router-dom';
import React from 'react';
import CookieStorageService from '@/services/CookieStorageService';
import AuthService from '@/services/AuthService';
import LocalStorageService from './services/LocalStorageService';

interface Props {
  component: React.ComponentType;
  path?: string;
}

export const PrivateRoute: React.FC<Props> = ({
  component: RouteComponent,
}) => {
  // const pathname = location.pathname;
  // LocalStorageService.removeItem('current_url');
  const isAuthenticated = CookieStorageService.getItemByKey(
    AuthService.getAuthKey()
  );

  if (isAuthenticated !== undefined && isAuthenticated) {
    return <RouteComponent />;
  }

  // LocalStorageService.setItem('current_url', pathname);
  return <Navigate to='/' />;
};
