import styled, { device, themeGet } from '@/shared/styled';

export const StyledLogo = styled.div`
 padding-bottom:40px;
 padding-top:40px;
 img{
  width: 100px;
 }
`;

export const StyledWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding:0px 30px;
  background-image: url("https://static-web.fra1.cdn.digitaloceanspaces.com/numbergame/background.svg");
  background-repeat: repeat-y;
  background-size: 100%;
  height: 100vh;
  .no_padding {
    padding-right: 0px;
  }
  .signin_btn {
    display: flex;
    align-items: center;
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    font-size: 16px;
    gap: 4px;
    color: ${themeGet('colors.white')};
    width: 100%;
    margin-top: 10px;
    height: 52px;
    border-radius: 10px;
    border: 1px solid #2E51E9;
    background: linear-gradient(271deg, #395CF1 2.98%, #5370F5 100%);
  }
  .btn-check:checked + .signin_btn.btn,
  :not(.btn-check) + .btn:active,
  .signin_btn.btn:first-child:active,
  .signin_btn.btn.active,
  .signin_btn.btn.show {
    border: 1px solid #2E51E9;
    background: linear-gradient(271deg, #395CF1 2.98%, #5370F5 100%);
    color: ${themeGet('colors.white')};
  }
  .row {
    margin-bottom: 14px;
  }
  .mobilenumber {
    padding-left: 12px !important;
    height:52px;
  }
  ${device.MobToMd} {
    .row {
      // margin-bottom: 0px;
      .col-md-12 {
        margin-bottom: 10px;
      }
    }
    .no_padding {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      margin-bottom: 0px;
    }
    .mobilecode {
      width: 125px;
      padding-right: 0px !important;
    }
    .mobilephone {
      //width: calc(100% - 125px);
    }
  }
  .posswordicon {
    position: absolute;
    height: 52px;
    right: 0px;
    top: 0px;
    width: 30px;
    display: flex;
    justify-content: center;
    z-index: 99;
    button {
      padding: 0px;
      width: 30px;
      &:active {
        box-shadow: none;
        color: inherit;
        background-color: inherit;
        border-color: inherit;
        border: none;
      }
      &:focus {
        box-shadow: none;
        color: inherit;
        background-color: inherit;
        border-color: inherit;
        border: none;
      }
    }
    svg {
      path {
        fill: #6a7a9b;
      }
    }
  }
  .infopassword {
    margin-left: 6px;
    position: relative;
    top: -2px;
  }
  .passwodicon {
    width: 35px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  // input:-webkit-autofill,
  // input:-webkit-autofill:hover,
  // input:-webkit-autofill:focus,
  // input:-webkit-autofill:active {
  //   // -webkit-background-clip: text;
  //   // -webkit-text-fill-color: #ffffff;
  //   // transition: background-color 5000s ease-in-out 0s;
  //   // box-shadow: inset 0 0 20px 20px #23232329;
  //   // background-clip: text !important;
  //   // background: ${themeGet('colors.forminputbg')} !important;
  //   -webkit-text-fill-color: #fff;
  //   -webkit-box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 1000px inset !important;
  //   //transition: background-color 5000s ease-in-out 0s;
  //   background-color:rgba(0, 0, 0, 0) !important;
  //   background-color:rgb(0, 0, 0,) !important;
  //   box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 1000px inset !important;
  // }
  // input:-internal-autofill-selected {
  //   background-clip: padding-box !important;
  //   background-color: #000 !important;
  //   border: 1px solid #1c2335;
  //   border-radius: 6px 6px 6px 6px !important;
  //   //box-shadow:none !important;
  //   background: ${themeGet('colors.forminputbg')} ;
  //   background-clip: padding-box !important;
  //   // -webkit-background-clip: text !important ;
  //   // -webkit-text-fill-color: transparent !important;
  //   -webkit-text-fill-color: #fff;
  //   -webkit-box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 1000px inset !important;
  //   //transition: background-color 5000s ease-in-out 0s;
  // }
  // input:-internal-autofill-selected:focus{
  //   background-color: ${themeGet('colors.forminputbg')} !important;
  //   //box-shadow:none !important;
  //   background-clip: padding-box !important;
  //   //background-clip: text !important;
  //   background: ${themeGet('colors.forminputbg')} !important;
  //   background-color:rgba(0, 0, 0, 0) !important;
  //   -webkit-text-fill-color: #fff;
  //   -webkit-box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 1000px inset !important;
  //   //transition: background-color 5000s ease-in-out 0s;
  // }
//   input:-webkit-autofill:hover,
//   input:-webkit-autofill:focus,
//   input:-webkit-autofill:active {
//     background-clip: padding-box !important;
//     // background-clip: text !important;
//     // -webkit-text-fill-color: rgb(255, 255, 255);
//     // box-shadow: none !important;
//     // transition: inherit !important;
//     // background-color: ${themeGet('colors.forminputbg')} !important;
//     // -webkit-background-clip: text !important ;
//     // -webkit-text-fill-color: transparent !important;
//     -webkit-text-fill-color: #fff;
//     -webkit-box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 1000px inset !important;
//     //transition: background-color 5000s ease-in-out 0s;
//     background-color:rgba(0, 0, 0, 0) !important;
//     background-color:rgb(0, 0, 0,) !important;
//     box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 1000px inset !important;
// }
.form-control {
  // display: block;
  // width: 100%;
  // padding: $input-padding-y $input-padding-x;
  // font-family: $input-font-family;
  // @include font-size($input-font-size);
  // font-weight: $input-font-weight;
  // line-height: $input-line-height;
  // color: $input-color;
  //background-color: ${themeGet('colors.forminputbg')} !important;;
  border-radius: 6px;
  //border: 1px solid #DCDCDC;
  background: ${themeGet('colors.white')};
  //background-clip: padding-box;
  box-shadow: none !important;
  //border: 1px solid ${themeGet('colors.largegamecardborder')}!important ;
  appearance: none; // Fix appearance for date inputs in Safari
  // background-color:rgba(0, 0, 0, 0) !important;
  // background-color:rgb(0, 0, 0,) !important;

}
// .input-group input:autofill:hover, .input-group input:autofill:focus, .input-group input:autofill:active {
//   background-clip: padding-box !important;
//   //box-shadow: none !important;
//   //transition: inherit !important;
//   background-color: ${themeGet('colors.forminputbg')} !important;
//   background: ${themeGet('colors.forminputbg')} ;
//   -webkit-text-fill-color: #fff;
//     -webkit-box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 1000px inset !important;
//     //transition: background-color 5000s ease-in-out 0s;

// }
input:-internal-autofill-selected{}
// input:-webkit-autofill,
// input:-webkit-autofill:hover, 
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {
//   //border: 1px solid green;
//   -webkit-text-fill-color: #fff;
//   -webkit-box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 1000px inset !important;
//   //transition: background-color 5000s ease-in-out 0s;
//   background-color:rgba(0, 0, 0, 0) !important;
//   background-color:rgb(0, 0, 0,) !important;
//   box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 1000px inset !important;
// }
  // input:-webkit-autofill,
  //   input:-webkit-autofill:focus {
  //   //transition: background-color 0s 600000s, color 0s 600000s;
  // }

  .loginform label {
    font-family: 'Sora',sans-serif;
    font-size: 14px;
    color:${themeGet('colors.NumberGameTextcolor')};
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 10px;
    display: flex;
  }
  .loginform{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 35px;
    ${device.MobToSmm}{
      padding: 0px 0px;
    }
  }
  .mobilenumber:focus {
    color: #232F4D;
    //background-color: #1c2335;
    border: 1.5px solid #232F4D;
    outline: 0;
    box-shadow: none;
}
`;

export const StyledTitle = styled.h3`
  color:${themeGet('colors.NumberGameTextcolor')};;
  font-family: 'Sora', sans-serif;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 6px;
  text-align: center;
  font-weight: 500;
  padding-bottom:30px;
`;

export const StyledDesc = styled.p`
  font-size: 16px;
  color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
  font-family: ${themeGet('fonts.googleSansRegular')};
  margin-bottom: 30px;
  text-align: center;
`;

export const StyledAlertMessage = styled.div`
  color: #f31415;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  position: relative;
  padding: 5px 5px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  border-radius: 8px;
  gap: 8px;
  padding: 8px;
  //color:${themeGet('colors.eventnumberdefaulttxtcolor')};
  font-size:13px;
  font-family: 'Sora', sans-serif;
  font-weight: 500;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
`;

export const StyledLabelWrap = styled.div`
  margin-bottom: 5px;
  justify-content: space-between;
  display: flex;
`;

export const StyledDivider = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 15px 0;
  color: #c2c9d6;
  &:after,
  &:before {
    content: '';
    display: block;
    flex: 1;
    height: 2px;
    background-color: ${themeGet('colors.tableheaderborder')};
  }
  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }
`;

export const StyledBottomText = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
  color: rgba(35, 47, 77, 0.80);
  font-family: 'Sora',sans-serif;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 0px;
  margin-top: 40px;
  margin-bottom: 30px;
  margin-left: -40px;
  margin-right: -40px;
  ${device.MobToMd}{
    margin-left: -20px;
    margin-right: -20px;
  }
  a {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 16px;
  }
`;

export const StyledForgotText = styled.div`
  text-align: center;
  padding: 5px 0px;
  a {
    background: none;
    color: ${themeGet('colors.mainborder')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 15px;
  }
  ${device.MobToMd} {
    margin-top: 10px;
  }
`;
export const StyledPhoneText = styled.div`
  position: absolute;
  left: 31px;
  top: 11px;
  color: #f0f0f0;
  font-size: 13px;
  z-index: 99;
`;

export const StyledPersonalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
