import CookieStorageService from '@/services/CookieStorageService';
import { post, get, put } from '@/services/HttpService';

export enum Auth {
  AUTH_ACCESS_TOKEN = 'accessToken',
}

const BASE_URL = process.env.REACT_APP_AUTHENTICATION_API_URL;
const CMS_URL = process.env.REACT_APP_RACING_API_URL;

export default class AuthService {
  public static authenticate = async (data: ILoginForm) => {
    const response = await post(`${BASE_URL}login`, data);
    if (response.access_token != undefined) {
      CookieStorageService.setItem(Auth.AUTH_ACCESS_TOKEN, response.access_token, 1);
      return { status: true, data: response };
    } else {
      return { status: false, data: response.error };
    }
  };

  public static isAuthenticated = (): boolean => {
    return CookieStorageService.getItem(Auth.AUTH_ACCESS_TOKEN) ? true : false;
  };

  public static getAuthKey() {
    return Auth.AUTH_ACCESS_TOKEN;
  }

  public static submitBet = async (data: any) => {
    const response = await post(`${BASE_URL}submitbet`, data);
    if (response.status == "SUCCESS") {
      return { status: true, data: response.data };
    } else {
      return { status: false, data: response.message };
    }
  };

  public static events = async (val: any) => {
    const response = await get(`${BASE_URL}events`);
    if (response[val] != undefined && response[val].events.length > 0) {
      return { status: true, data: response[val] };
    } else {
      return { status: false, data: response.error };
    }
  };

  public static agentBalance = async (val: any) => {
    const response = await post(`${BASE_URL}agent/balance`, { cardId: val });
    if (response != undefined) {
      return { status: true, data: response };
    } else {
      return { status: false, data: response };
    }

  };

}
