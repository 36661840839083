import styled, { device, themeGet } from '@/shared/styled';

export const StyledDashboardHead = styled.div`
z-index: 20;
top: 0px;
left: 0px;
right: 0px;
width: 100%;
position: sticky;
height: 58px;
background: ${themeGet('colors.topheaderbackground')};
border-bottom: 1px solid rgba(255, 255, 255, 0.15);
font-size: 24px;
font-weight: 600;
font-family: 'Sora', sans-serif;
color:${themeGet('colors.white')};
text-align: center;
display: flex;
align-items: center;
justify-content: center;
.backarrow{
  position: absolute;
  left: 16px;
  top: 10px;
}
`;

export const StyledSupportContainerFluid = styled.div`
  transition: all 0.3s;
  background: linear-gradient(180deg, #F3F3FC 0%, #DFDFEC 91.6%);
  box-shadow: 0px -20px 60px 0px rgba(0, 0, 0, 0.15);
  z-index: 999;
  width: 100%;
  position: absolute;
  top: 58px;
  min-height: calc(100vh - 128px);
  padding: 16px 16px 100px 16px;
`;

export const StyledSupportHead = styled.div`
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position:relative;
  a{
    position: absolute;
    left: 0px;
    top: 3px;
    svg{
      path{
        fill: #232F4D;
      }
    }
  }
  strong{
    font-family: 'Sora',sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    display: flex;
    width: 100%;
    justify-content: center;
  }
`;

export const StyledSupportContainer = styled.div`
  width:100%;
  margin-bottom: 15px;
  h6{
    color: #232F4D;
    font-family: 'Sora',sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 6px;
  }
  p{
    color: rgba(0, 0, 0, 0.85);
    font-family: 'Sora', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const StyledSupportContact = styled.div`
  width:100%;
  label{
    color: #232F4D;
    font-family: 'Sora', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    width: 100%;
    padding-bottom:8px;
  }
  p{
    padding: 12px;
    height: 54px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #A1AEB6;
    background: rgba(255, 255, 255, 0.20);
    span{
      color: #232F4D;
      font-family: 'Sora',sans-serif;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      display: flex;
      flex: 1;
    }
    button{
      background: none;
      border: none;
      span.active{
        svg{
         path{
          fill:#F54646;
         }
        }
      color:#F54646;
      }
      svg{
        path{
          fill: ${themeGet('colors.topheaderbackground')};
        }
      }
    }
  }
`;

export const StyledAccordionContainer = styled.div`
  width:100%;
  margin:30px 0px 0px 0px;
  h3{
    color: #232F4D;
    font-family: 'Sora', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom:20px;
  }
`;

export const StyledAccordionContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  .accordion-item{
    margin-bottom: 14px;
    color: #B7CBC5;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #A1AEB6;
    background: rgba(255, 255, 255, 0.20);
    .accordion-button{
      color: #000;
      font-family: 'Sora', sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      background: rgba(255, 255, 255, 0.20);
      box-shadow: none;
      padding: 14px;
      &::after{
        background-image:url('https://static-web.fra1.cdn.digitaloceanspaces.com/numbergame/add-square-icon.svg');
      }
      &:not(.collapsed)::after{
        background-image:url('https://static-web.fra1.cdn.digitaloceanspaces.com/numbergame/minus-square-icon.svg');
      }
    }
  }
  .accordion-body{
    background: rgba(255, 255, 255, 0.20);
    padding: 0px 14px 14px 14px;
    ul{
      margin-left:16px;
      li{
        color: #000;
        font-family: 'Sora', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 12px;
        &:last-child{
          margin-bottom: 0px;
        }
      }
    }
  }
`;