import styled, { device, themeGet } from '@/shared/styled';

export const StyledFooter = styled.footer`
height: 70px;
position: relative;
background:#DFDFEC;
//background: linear-gradient(180deg, #F3F3FC 0%, #DFDFEC 91.6%);
        // box-shadow: 0px -20px 60px 0px rgba(0, 0, 0, 0.15);
//background: #D5D7DD;
//background: ${themeGet('colors.bodybackground')};

  p {
    color: #000;
  }
`;

export const StyledFooterSupport = styled.div`
  align-items: center;
  background: #1c1c1c;
  flex-direction: column;
  display: flex !important;
  justify-content: center;
  padding: 22px 0 16px;
  position: relative;
  p {
    color: #b5b5b5;
    font-family: ${themeGet('fonts.googleSansRegular')} !important;
    font-size: 14px;
    line-height: 20px;
    margin: 0px;
  }
  a {
    color: #b5b5b5 !important;
    font-size: 13px;
    text-decoration: underline;
    transition: color 0.3s;
    font-family: ${themeGet('fonts.googleSansBold')};
  }
  ${device.TabToLg} {
    padding: 20px 16px;
    p {
      font-size: 12px;
      line-height: 17px;
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
    }
    &:last-chaild {
      padding-top: 10px;
    }
  }
`;

export const StyledFooterMain = styled.div`

background:${themeGet('colors.white')};
border-radius: 20px 20px 0px 0px;
//border-top:1px solid #000;
  height: 70px;
  position: fixed;
  //left: 0px;
  min-width: 500px;
  bottom: 0px;
  fill: rgba(255, 255, 255, 0.05);
  // stroke-width: 1px;
  // stroke: #FFF;
  //backdrop-filter: blur(2px);
  padding:14px 14px;
  justify-content: space-between;
  display: flex;
  z-index: 999;
  ${device.MobToSmm}{
    min-width: 100%;
    width: 100%;
  }
  ul{
    list-style: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    //height: 70px;
    li{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        p{
          font-family: 'Sora', sans-serif;
          color:#6579D6;
          font-size: 11px;
          font-weight: 600;
          line-height: 23px;
        }
      }
      &.active{
        a{
          svg{
            path{
              fill:#F54646;
            }
          }
          p{
            color:#F54646;
          }
          
        }
      }
    }
  }
  .placebetview{
    height: 85px;
    width: 85px!important;
    // position: fixed;
    // top: -14px;
    border-radius: 100%;
    left:40%;
    background:${themeGet('colors.white')};
    // border-left:1px solid transparent;
    // border-right:1px solid transparent;
    // border-bottom:1px solid transparent;
    // border-top: 1px solid rgba(255, 255, 255, 0.5);
    z-index: 9;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-51%, -56%);
  }
  .disabled a{
    pointer-events: none;
  }
 .placebetbutton{
  //background:${themeGet('colors.topheaderbackground')};
  border: 2px solid #3D61FF;
  background: linear-gradient(86deg, #3B60FE 3.24%, #5573FE 94.68%);

  width: 70px;
  height: 70px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p{
    color:${themeGet('colors.white')};
    line-height: 16px;
    text-shadow: -0.8px 0px 0.1px #000;
  }
  h6{
    font-size: 20px;
    font-family: 'Sora', sans-serif;
    color:${themeGet('colors.white')};
    font-weight: 700;
    line-height: 20px;
    text-shadow: -0.8px 0px 0.1px #000;
  }
 }

 .placebet{
  width: 250px;
  position: relative;
   li{
    width: 100%;
    position: relative;
   }
 }

 .disabled {
  .placebetbutton{
    pointer-events: none;
    //opacity: .4;
    border: 2px solid #A0ACC0;
    background: linear-gradient(86deg, #A0ACC0 3.24%, #B5C0D2 94.68%);
   }
}
.notification{
  position: absolute;
  top: -28px;
  left: 50%;
  border-radius: 10px;
  border: 2px solid #FF9638;
  background: #FED034;
  transition: all 0.3s;
  padding: 5px 5px 3px 6px;
  min-width: 50px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 30px;
  align-items: center;
  transform: translateX(-50%);
  z-index: 100;
  .bets{
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 600;
    color:#000;
    line-height: 13px;
    position: relative;
    top: -4px;
    h4{
      color: #D9560D;
      font-size: 10px;
      font-weight: 600;
      font-family: 'Sora', sans-serif;
      display: flex;
      align-items: baseline;
      b{
        color: #000;
        font-size: 11px;
        margin-right: 3px;
        font-weight: 600;
      }
    }
  }
  .total{
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 600;
    color:#000;
    line-height: 13px;
    position: relative;
    top: -10px;
    h4{
      color: #D9560D;
      font-size: 10px;
      font-weight: 600;
      font-family: 'Sora', sans-serif;
    
    }
  }
  .amounticon{
    font-weight: 600;
    white-space: nowrap;
    span{
      font-weight: 400;
    }
  }
}
`;

export const StyledFooterContainer = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex !important;
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
    padding: 0 12px;
  }
  ${device.TabToLg} {
    flex-direction: column;
  }
`;

export const StyledFooterLogo = styled.div`
display: flex;
align-items: center;
.nemologoview{
  border-left: 1px solid #595959;
  padding-left: 15px;
  margin-left: 15px;
}
 .nemologo{
  width: 90px;
 }

 ${device.MobToSmm}{
  .arionplaylogo{
    width: 150px;
  }
  .nemologo{
    width: 80px;
  }
 }
`;

export const StyledFooterPartnered = styled.div`
  ul {
    display: flex !important;
    align-items: center;
    gap: 10px;
    list-style: none;
  }
  img {
    transition: opacity 0.3s;
  }
  ${device.TabToLg} {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  ${device.MobToSL}{
    img{
      width: 100%;
    }
  }
`;

export const StyledFooterSocialMedia = styled.div`
  ul {
    display: flex !important;
    align-items: center;
    gap: 10px;
    list-style: none;
    a:hover{
      svg path{  fill: ${themeGet('colors.btnfontcolor')};
    }
  }
  svg {
    overflow: hidden;
  }
  img,
  svg {
    vertical-align: middle;
  }
  svg path {
    fill: #e1e7ed;
    transition: all .3s;
  }
  ${device.TabToLg} {
    a{ display: flex !important;}
    a:hover{
      svg path{  fill: ${themeGet('colors.btnfontcolor')};
    }
  }
`;

export const StyledFooterMenu = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  display: flex !important;
`;

export const StyledFooterMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${device.xlarge} {
    float: inherit;
    margin: 0 auto;
    padding: 0 12px;
    width: 1280px !important;
  }
  ${device.TabToLg} {
    flex-direction: column;
  }
`;

export const StyledFooterMenuListContainer = styled.div`
  ul {
    display: flex !important;
    list-style: none;
  }
  li {
    active{ color: ${themeGet('colors.btnfontcolor')};}
    &:last-child {
      a {
        border-right: none;
        }
    }
  }
  li a {
    border-right: 1px solid #595959;
    color: #b5b5b5;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 14px;
    margin-right: 12px;
    padding-right: 12px;
    text-decoration: none;
    transition: color 0.3s;
    &:hover {
      color: ${themeGet('colors.btnfontcolor')};
    }
    &:focus {
      color: ${themeGet('colors.btnfontcolor')};
    }
    &.active {
      color: ${themeGet('colors.btnfontcolor')};
    }
    .active {
      color: ${themeGet('colors.btnfontcolor')};
    }
  }
  &.active {
    color: ${themeGet('colors.btnfontcolor')};
  }
  ${device.TabToLg} {
    ul {
      float: left;
      width: 100%;
      display: initial !important;
      text-align: center;
      margin: 0px auto;
    }
    li {
      display: inline-block;
      margin-bottom: 7px;
      width: auto;
      &:last-child {
        a {
          border-right: 0px !important;
        }
      }
    }
    a {
      font-size: 12px !important;
      margin-right: 10px !important;
      padding-right: 10px !important;
      float: left;
    }
  }
`;

export const StyledFooterCopyRight = styled.div`
  p {
    //margin-top: 16px;
    font-size: 14px;
    color: ${themeGet('colors.copyrighttextcolor')};
    margin-bottom: 0px;
  }
  span {
    font-size: 13px;
    position: relative;
    top: 2px;
  }
  p.version_text {
    font-family: ${themeGet('fonts.googleSansBold')} !important;
    font-size: 10px !important;
    text-align: right;
  }
  ${device.TabToLg} {
    p {
      font-size: 11px;
      //padding-left: 10px;
      text-align: center;
      white-space: break-spaces;
    }
    span {
      font-size: 16px;
      position: relative;
      top: 2px;
    }
  }
`;
