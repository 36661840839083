import {
  FormGroup,
  Label,
  Input,
  Button,
  ButtonSpinner,
  Select,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import {
  setIsAuthenticatedData,
  setUserData
} from '@/redux/slices/login';
import { useAppSelector } from '@/hooks/useAppSelector';
import { hasKey } from '@/shared/methods';
import AuthService from '@/services/AuthService';
import {useNavigate } from 'react-router-dom';

import {
  StyledWrap,
  StyledTitle,
  StyledAlertMessage,
  StyledPersonalContainer,
  StyledLogo,
  StyledBottomText,
} from './style';
import {
  ErrorWarningiconSvg,
  EyeHideIconSvg,
  EyeIconSvg,
} from '@/shared/svg';
import CookieStorageService from '@/services/CookieStorageService';

interface ChildProps {

}

const SigninForm = (props: ChildProps) => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const [message, setMessage] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [isNotValid, setIsNotValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isLoading, isError, errorMessage } = useAppSelector(
    state => state.auth
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginForm>();

  const showPassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };

  const onSubmit: SubmitHandler<ILoginForm> = async data => {
    setLoading(true);
    const response = await AuthService.authenticate(data);
    if (response.status) {      
        setLoading(false);
        dispatch(setUserData(response.data));
        localStorage.setItem('Username', response.data.DisplayName);
        localStorage.setItem('cardId', response.data.Username);
        dispatch(setIsAuthenticatedData(true));
        history('/dashboard');
    } else {
      setLoading(false);
      setIsNotValid(true);
      setMessage(response.data);
    }
  };

  const checkUser = async() =>{
    const token = await CookieStorageService.getItem(AuthService.getAuthKey());
    if (token != undefined) {
      history('/dashboard');
    }
  }

  useEffect(() => {
    setLoading(isLoading);    
    if (isError) {
      setIsNotValid(true);
      setMessage(errorMessage);
    }
  }, [isError, isLoading]);

  useEffect(() => {
    checkUser();
  },[]);

  return (
    <StyledWrap>

      <StyledLogo>
       <img src='https://static-web.fra1.cdn.digitaloceanspaces.com/numbergame/gamanumberlogo.svg'/>
      <Link to=''>

      </Link>
     </StyledLogo>
      <StyledTitle>Agent Login</StyledTitle>
      {/* <StyledDesc>Login to {process.env.REACT_APP_SITE_TITLE}.</StyledDesc> */}
      <form
        className='loginform'
        autoComplete="off"
        action='#'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {isNotValid ? (
          <StyledAlertMessage>
            <ErrorWarningiconSvg /> {message}
          </StyledAlertMessage>
        ) : null}

        <Row mb='20px'>
          <Col md={12} className='mobilephone'>
            <FormGroup>
              <Label display='block' mb='5px' htmlFor='email'>
                Username
              </Label>
              <InputGroup> 
                <Input
                  className='mobilenumber'
                  type='tel'
                  id='username'
                  name='username'
                  placeholder='Enter your username'
                  feedbackText={errors?.username?.message}
                  state={hasKey(errors, 'username') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'username')}
                  {...register('username', {
                    required: 'Username is required',
                  })}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row mb='20px'>
          <Col md={12}>
            <Label display='block' mb='5px' htmlFor='email'>
              Enter Password
            </Label>
            <InputGroup>
              <Input
                id='password'
                className='mobilenumber'
                type={passwordType}
                placeholder='Enter your password'
                feedbackText={errors?.password?.message}
                state={hasKey(errors, 'password') ? 'error' : 'success'}
                showState={!!hasKey(errors, 'password')}
                {...register('password', {
                  required: 'Password is required',
                  minLength: {
                    value: 4,
                    message: 'Minimum length is 4',
                  },
                })}
              />
              <InputGroupAddon className='posswordicon'>
                <div
                  className='passwodicon'
                  color='light'
                  onClick={() => showPassword()}
                >
                  {passwordType === 'password' && <EyeHideIconSvg />}
                  {passwordType === 'text' && <EyeIconSvg />}
                </div>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
        {/* <StyledForgotText>
          <Link
            className='flex'
            to={''}
            onClick={() => openInfoPopup('forgot')}
          >
            Forgot Password?
          </Link>
        </StyledForgotText> */}
        <StyledPersonalContainer>
          <Button type='submit' color='brand2' className='signin_btn'>
            {loading ? <ButtonSpinner /> : null}Login
          </Button>
        </StyledPersonalContainer>
        <StyledBottomText>
          Copyright <span>&copy;</span> 2023
        </StyledBottomText>
      </form>
    </StyledWrap>
  );
};

export default SigninForm;
function setValue(arg0: string) {
  throw new Error('Function not implemented.');
}

