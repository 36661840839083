import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  GameWithdrawIconSvg,
  GameExchangeIconSvg,
  LogoutIconSvg,
} from '@/shared/svg';
import CookieStorageService from '@/services/CookieStorageService';
import {
  StyledHeaderWrapper,
  StyledHeaderContainer,
  StyledHeaderGame,
  StyledGameAmount,
  StyledPhoneText,
  StyledHeaderLogo,
  StyledLogout,
} from './style';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { InputGroup } from 'react-bootstrap';
import AuthService from '@/services/AuthService';
import {
  resetUser,
  setIsAuthenticatedData,
  setWalletData,
} from '@/redux/slices/login';
import { history } from '@/helpers/history';
import { StyledGameInfopop, StyledPopupView } from '@/pages/gameon/style';
import { useAppSelector } from '@/hooks/useAppSelector';

const Header = () => {
  const dispatch = useAppDispatch();
  const [logoutPopup, setLogoutPopup] = useState(false);
  const { walletInfo } = useAppSelector(state => state.auth);
  const [walletBalInfo, setWalletBalInfo] = useState({
    userId: '',
    limit: 0,
    balance: 0,
    sale: 0,
  });

  const logoutUser = async () => {
    setLogoutPopup(true);
  };

  const confirmLogout = () => {
    CookieStorageService.deleteCookieData(AuthService.getAuthKey());
    dispatch(setIsAuthenticatedData(false));
    dispatch(resetUser());
    localStorage.removeItem('Username');
    localStorage.removeItem('cardId');
    setLogoutPopup(false);
    history.push('/login');
    window.location.reload();
  };

  const closeLogout = () => {
    setLogoutPopup(false);
  };

  const getWalletBalance = async () => {
    const cardId = localStorage.getItem('cardId');
    const response = await AuthService.agentBalance(cardId);
    dispatch(setWalletData(response.data));
  };

  useEffect(() => {
    getWalletBalance();
  }, []);

  useEffect(() => {
    if (walletInfo !== undefined) {
      setWalletBalInfo(walletInfo);
    }
  }, [walletInfo]);

  return (
    <>
      {logoutPopup && (
        <StyledPopupView className='1234'>
          <StyledGameInfopop>
            <h4>Confirm</h4>
            <div className='border'></div>
            <p>Are you sure you want to logout</p>

            <div className='loginform'>
              <div className='buttonview'>
                <Link
                  className='closebtn'
                  to={'#'}
                  onClick={() => closeLogout()}
                >
                  Cancel
                </Link>
                <Link
                  className='confirmbtn'
                  to={'#'}
                  onClick={() => confirmLogout()}
                >
                  Log-out
                </Link>
              </div>
            </div>
          </StyledGameInfopop>
        </StyledPopupView>
      )}
      <StyledHeaderWrapper>
        <StyledHeaderContainer>
          <StyledHeaderGame>
            <StyledHeaderLogo>
              <Link to='/dashboard'>
                <img
                  src='https://static-web.fra1.cdn.digitaloceanspaces.com/numbergame/logo.svg'
                  alt=''
                />
              </Link>
            </StyledHeaderLogo>
            <StyledGameAmount>
              <div className='walletamount'>
                <span>
                  <GameWithdrawIconSvg />
                </span>
                <InputGroup>
                  <StyledPhoneText>&#8369;</StyledPhoneText>
                  <div id='walletAmount' className='wallet-amt'>
                    {walletBalInfo.balance}
                  </div>
                </InputGroup>
              </div>
              <div className='walletexchange'>
                <span>
                  <GameExchangeIconSvg />
                </span>
                <InputGroup>
                  <StyledPhoneText>&#8369;</StyledPhoneText>
                  <div id='transactions' className='wallet-amt'>
                    {walletBalInfo.sale}
                  </div>
                </InputGroup>
              </div>
            </StyledGameAmount>
            <StyledLogout>
              <Link to={'#'} onClick={() => logoutUser()}>
                <LogoutIconSvg />
                <span>Log Out</span>
              </Link>
            </StyledLogout>
          </StyledHeaderGame>
        </StyledHeaderContainer>
      </StyledHeaderWrapper>
    </>
  );
};
export default Header;
