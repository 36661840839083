import styled, {device, themeGet } from '@/shared/styled';


export const StyledAgeContainer = styled.div`
  display: block;
`;

export const StyledAgeOverLay = styled.div`
  // background: hsla(0, 0%, 5%, 0.7);
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(6px);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  z-index: 9991;
`;

export const StyledOverlayContainer = styled.div`
//border-radius: 20px;
border: 1px solid rgba(255, 255, 255, 0.25);
background: #DAE0EB;
backdrop-filter: blur(20px);
  //background: ${themeGet('colors.white')};
  border-radius: 30px;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 16px;
  z-index: 9999;
  height:auto;
  width: calc(100% - 48px);
`;

export const StyledOverlayHead = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  h4{
    font-size: 16px;
    font-weight: 500;
    color:${themeGet('colors.NumberGameTextcolor')};
    margin-bottom: 4px;
  }
`;

export const StyledHead = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: left;
  position: relative;
  margin-top: 10px;
  flex-direction: column;
  h6{
    font-size: 13px;
    font-weight: 400;
    color:${themeGet('colors.NumberGameTextcolor')};
  }
  a{
    position: absolute;
    top: -15px;
    right: 0px;
  }
`;

export const StyledEventTime = styled.div`
display: flex;
flex-direction: column;
p{
  color: #00295F;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Sora', sans-serif;
  padding:16px 0px 0px 0px;
}
ul{
  display: flex;
  gap: 4px;
  width: 100%;
  li{
    height:35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    border: 1px solid #C9CDD0;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    padding: 12px 16px;
    a{
      color:${themeGet('colors.NumberGameTextcolor')};;
      font-size: 15px;
      font-family: 'Sora', sans-serif;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        font-weight: 400;
        line-height: 20px;
      }
    }

  }
  li.active{
    border-radius: 16px;
    border: 1px solid #395CF1;
    background: linear-gradient(270deg, #3A5DF4 8.2%, #5471F8 100%);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    a{
      color: ${themeGet('colors.white')};
    }
  }
  }
`;

export const StyledOverlayBody = styled.div`
height: calc(100% - 110px);
text-align: center;
overflow-y: auto;
  
`;

export const StyledCustomInput = styled.div`
position: relative;
.custominput{
  border-radius: 10px;
border: 1.5px solid #232F4D;
background: ${themeGet('colors.white')};
padding:6px 16px 6px 35px;
height:52px;
font-size: 20px;
font-family: 'Sora', sans-serif;
font-weight: 600;
text-align: left;
  display: flex;
  align-items: center;
  margin: 12px 0px 24px 0px;
  position: relative;
  .straighticon{
    position: absolute;
  left: 16px;
  }
}
.custom-error{
border:1px solid #ff0000;
}
.custom-error-message{
  color: #ff0000;
  font-size: 10px;
  position: absolute;
  top: 51px;
  right: 0px;
  text-align: right;
}
`;

export const StyledCustonNumber =styled.div`
ul{
  list-style: none;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  gap: 15px;
  //padding-top:16px;
  //padding:0px 10px 0px 10px;
 // margin-bottom: 20px;
  li{
      display: flex;
      width: 100%;
      height:100%;
      margin-bottom: 10px;
        a{
          display: flex;
          width: 100%;
          height: 100%;
          text-align: center;
          align-items: center;
          justify-content: center;
          font-size: 30px;
          font-family: 'Sora', sans-serif;
          color:${themeGet('colors.NumberGameTextcolor')};
          background:${themeGet('colors.white')};
          box-shadow: 0 5px rgba(160, 172, 192, 0.50);
          border-radius: 10px;
          font-weight: 400;
 
        }

   }

   li a:active {
    background:${themeGet('colors.white')};
    box-shadow: 0 5px rgba(160, 172, 192, 0.50);
    color:${themeGet('colors.white')};
    transform: translateY(5px);
   }
   .addqueue a{
    background: #31A354;
    box-shadow: 0 10px #006D2C;
    display: flex;
    flex-direction: column;
    a{
      flex-direction: column;
      color:${themeGet('colors.white')};
      background-clip:inherit;
      -webkit-text-fill-color: currentColor;
      background: none;
    }
    p{
      font-size: 13px;
      font-weight: 700;
      font-family: 'Sora', sans-serif;
      color:${themeGet('colors.white')};
    }
    h6{
      font-size: 16px;
      font-weight: 700;
      font-family: 'Sora', sans-serif;
      color:${themeGet('colors.white')};
    }
   }
   .removeback a{
    background: var(--contra-red-500, #FB6A4A);
    box-shadow: 0 5px #D03B35;
   }
   li.removeback a:active {
    background: #FB6A4A;
    box-shadow: 0 5px #D03B35;
    transform: translateY(5px);
   }
   li.addqueue a:active {
    box-shadow: 0 5px #006D2C;
    transform: translateY(5px);
   }
   
}
.confirmbtn{
  border-radius: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  background: linear-gradient(270deg, #3B60FE 0.03%, #5573FE 99.98%);
  color:${themeGet('colors.white')};
  padding: 16px 10px;
  height:56px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Sora', sans-serif;
  margin-top:24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
`;

export const StyledOverlatTextView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    circle {
      fill: ${themeGet('colors.mainborder')};
    }

    path:last-child {
      fill: ${themeGet('colors.white')};
    }
  }
  h4 {
    font-size: 24px;
    margin-bottom: 20px;
    color: ${themeGet('colors.white')};
    font-family: ${themeGet('fonts.googleSansMedium')};
  }
  p {
    color: #cecece;
    font-size: 17px;
    margin-top: 10px;
    margin-bottom: 0px;
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
  .agree {
    font-size: 17px;
    margin-bottom: 25px;
    margin-top: 0px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    a {
      color: ${themeGet('colors.mainbackground')};
      text-decoration: underline;
    }
  }

  .yeare_button {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 16px;
    border: 0px;
    gap: 4px;
    height: 42px;
    padding: 0px 24px;
    color: ${themeGet('colors.pagefontcolor')};
    border-radius: 6px;
    &:hover {
      background: ${themeGet('colors.mainborder')};
      font-size: 16px;
      border: 0px;
      height: 42px;
    }
  }

  .exit_button {
    background: none;
    border: 1px solid ${themeGet('colors.mainborder')};
    color: ${themeGet('colors.btnfontcolor')};
    display: flex;
    align-items: center;
    gap: 4px;
    height: 42px;
    font-size: 16px;
    font-family: ${themeGet('fonts.googleSansBold')};
    padding: 0px 24px;
    border-radius: 6px;
    &:hover {
      background: none;
      border: 1.5px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
      height: 42px;
      font-size: 16px;
    }
    &:active {
      background: none;
      border: 1px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
    }
  }
`;

export const StyledAgeButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
`;
