import React, { useState, useEffect, useMemo } from 'react';
import {
  StyledDashboardContainerFluid,
  StyledDashboardContainer,
  StyledUserDetails,
  StyledDashboardmainView,
  StyledDashboardView,
} from './style';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  NumberOneIconSvg,
  NumberTwoIconSvg,
  NumberThreeIconSvg,
  UserProfileIconSvg,
  GameNumberOneIconSvg,
  GameNumberThreeIconSvg,
  GameNumberTwoIconSvg,
} from '@/shared/svg';
import { useAppSelector } from '@/hooks/useAppSelector';
import { setCartInfo, setCartTotal, setGameId } from '@/redux/slices/cart';
import { StyledGameInfopop, StyledPopupView } from '../gameon/style';
import moment from 'moment';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState('');
  const [programId, setProgramId] = useState(0);
  const [enableWarningPopup, setEnableWarningPopup] = useState(false);
  const [urlInfo, setUrlInfo] = useState('');
  const [urlId, setUrlId] = useState(0);
  const { userInfo } = useAppSelector(state => state.auth);
  const { gameId } = useAppSelector(state => state.cart);
  const history = useNavigate();
  const { cartInfo, cartTotal } = useAppSelector(state => state.cart);

  const redirectGame = (url: string, id: number) => {
    console.log(cartInfo.length,'cartInfo.length');
    console.log(programId,id);
    setUrlInfo(url);
    setUrlId(id);
    if ((programId === 0 || programId === id) && cartInfo.length == 0) {
      dispatch(setGameId(id));
      setTimeout(() => {
        history(url);
      }, 100);
    } else {
      setEnableWarningPopup(true);
    }
  };
  const warningClose = async () => {
    setEnableWarningPopup(false);
  };

  const warningProceed = async () => {
    dispatch(setCartInfo([]));
    dispatch(setCartTotal([]));
    setEnableWarningPopup(false);
    redirectNewGame(urlInfo, urlId);
  };

  const redirectNewGame = (url: string, id: number) => {
    setTimeout(() => {
      history(url);
    }, 100);
  };

  useEffect(() => {
    if (userInfo !== undefined && userInfo.Username !== undefined)
      setUserName(userInfo.DisplayName);
    else {
      setUserName(localStorage.getItem('Username'));
    }
  }, [userInfo]);

  useEffect(() => {
    setProgramId(gameId);
  }, [gameId]);

  // useEffect(()=>{
  //   if(gameId){
  //       alert("sadas"+gameId);
  //       setProgramId(gameId);
  //   }
  // },[gameId])

  return (
    <>
      {enableWarningPopup && (
        <StyledPopupView>
          <StyledGameInfopop>
            <h4>Are you sure?</h4>
            <p className='changetext'>
              1.Your current number selection will be lost if you change the
              Event. Would you like to proceed?
            </p>
            <div className='buttonview'>
              <Link
                className='closebtn'
                to={'#'}
                onClick={() => warningClose()}
              >
                Cancel
              </Link>

              <Link
                className='confirmbtn'
                to={'#'}
                onClick={() => warningProceed()}
              >
                proceed
              </Link>
            </div>
          </StyledGameInfopop>
        </StyledPopupView>
      )}
      <StyledDashboardContainerFluid>
        <StyledDashboardmainView>
          <StyledDashboardView>
            <StyledDashboardContainer>
              <h3>SELECT A GAME</h3>
              <ul>
                <li>
                  <Link
                    to={''}
                    onClick={() => redirectGame('/gameon/1/WIN', 1)}
                  >
                    <h5>Lucky Pick</h5>
                    <div className='number_wrap'>
                      <GameNumberOneIconSvg />
                      {/* <NumberOneIconSvg/> */}
                      <small>1</small>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link
                    to={''}
                    onClick={() => redirectGame('/gameon/3/TAN', 3)}
                  >
                    <h5>Swertres</h5>
                    <div className='three_no_wrapper'>
                      <div className='number_wrap'>
                      <GameNumberOneIconSvg />
                        {/* <NumberOneIconSvg /> */}
                        <small>1</small>
                      </div>
                      <div className='number_wrap lf_mr'>
                      <GameNumberTwoIconSvg />
                        {/* <NumberTwoIconSvg /> */}
                        <small>2</small>
                      </div>
                      <div className='number_wrap lf_mr'>
                        <GameNumberThreeIconSvg />
                        {/* <NumberThreeIconSvg /> */}
                        <small>3</small>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </StyledDashboardContainer>
            <StyledUserDetails>
              <div className='userlogged'>
                <p>Logged in as</p>
                <Link to='/'>
                  <UserProfileIconSvg />
                  {userName}
                </Link>
              </div>
            </StyledUserDetails>
          </StyledDashboardView>
        </StyledDashboardmainView>
      </StyledDashboardContainerFluid>
    </>
  );
};

export default Dashboard;
