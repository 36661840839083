import React, { useEffect, useState } from 'react';
import {
  StyledAgeContainer,
  StyledAgeOverLay,
  StyledOverlayContainer,
  StyledOverlayHead,
  StyledOverlayBody,
  StyledHead,
  StyledEventTime,
  StyledGameAmount,
  StyledGameAmountView,
  StyledGameInputText,
  StyledGameRow,
} from './style';
import {
  GameNumberOneIconSvg,
  GameNumberThreeIconSvg,
  GameNumberTwoIconSvg,
  NumberRemovebackIconSvg,
  PopUpCloseIconSvg,
  NoEventIconSvg,
} from '@/shared/svg/index';
import { Link, redirect } from 'react-router-dom';
import moment from 'moment';

interface childProps {
  selectedEventData: any;
  selectedChip: any;
  eventsData: any;
  chipsCount: any;
}

const EventNumber = (props: childProps) => {
  const [eventActiveIndex, setEventActiveIndex] = useState(0);
  const [chipActiveIndex, setChipActiveIndex] = useState('');
  const [keyBoardCount, setKeyBoardCount] = useState([]);
  const [chipValueOne, setChipValueOne] = useState('-');
  const [chipValueTwo, setChipValueTwo] = useState('-');
  const [chipValueThree, setChipValueThree] = useState('-');
  const [chipPosition, setChipPosition] = useState(0);
  const [price, setPrice] = useState(null);
  const [chipMultipleValue, setChipMultipleValue] = useState('');
  const buttonNumberMap = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
  const [enableChipError, setEnableChipError] = useState(false);

  const [values, setValues] = useState([null, null, null]);

  const handleNumberClick = (number: any) => {
    setEnableChipError(false);
    setValues(prevValues => {
      const newValues = [...prevValues];
      const emptyIndex = newValues.indexOf(null);
      if (emptyIndex !== -1) {
        newValues[emptyIndex] = number;
      }
      return newValues;
    });
  };

  const handleRemoveClick = () => {
    setValues(prevValues => {
      const newValues = [...prevValues];
      const queueValues = newValues.filter(v => v !== null);
      if (queueValues.length >= 0) {
        newValues[queueValues.length - 1] = null;
      }
      return newValues;
    });
  };

  const closePopup = async () => {
    window.location.href = '/dashboard';
  };

  const generateKeyBoard = async () => {
    var arrayCount = Array.apply(null, { length: 100 }).map(
      Number.call,
      Number
    );
    setKeyBoardCount(arrayCount);
  };

  const selectProgramData = async (item: any, ind: any) => {
    setEventActiveIndex(ind);
    props.selectedEventData(item, ind);
  };

  const chooseChip = async (val: any) => {
    if (val == '' && props.chipsCount.length > 1) return;
    if (props.eventsData.length == 0) {
      alert('No events are available.');
      return;
    }
    setChipActiveIndex(val);
    props.selectedChip(val);
  };

  const confirmMultiChip = async () => {
    if (props.eventsData.length == 0) {
      alert('No events are available.');
      return;
    }

    if (values[0] == null || values[1] == null || values[2] == null) {
      setEnableChipError(true);
      return;
    }
    setChipMultipleValue(
      chipValueOne + '-' + chipValueTwo + '-' + chipValueThree
    );
    const timeout = setTimeout(() => {
      props.selectedChip(
        `${values[0] !== null ? values[0] : '-'}${
          values[1] !== null ? values[1] : '-'
        }${values[2] !== null ? values[2] : '-'}`
      );
    });
  };

  useEffect(() => {
    generateKeyBoard();
  }, []);

  return (
    <StyledAgeContainer>
      <StyledAgeOverLay></StyledAgeOverLay>
      <StyledOverlayContainer
        className={`${
          props.eventsData.length > 0 && props.chipsCount.length == 1
            ? 'popupview'
            : 'popupview-multiple'
        }`}
      >
        <StyledOverlayHead>
          <StyledHead>
            {props.eventsData.length > 0 && <h4>Event</h4>}
            <Link to={'#'} onClick={() => closePopup()}>
              <PopUpCloseIconSvg />
            </Link>
          </StyledHead>
          {props.eventsData.length > 0 && (
            <StyledEventTime>
              <ul>
                {props.eventsData &&
                  props.eventsData.map((item: any, index: any) => {
                    return (
                      <li
                        key={index}
                        className={`${
                          eventActiveIndex === index ? 'active' : ''
                        }`}
                      >
                        <Link
                          to={'#'}
                          onClick={() => selectProgramData(item, index)}
                        >
                          {moment(item.startTime.slice(0, 2), 'HH').format('h')}
                          <span>
                            {moment(item.startTime.slice(0, 2), 'HH').format(
                              'a'
                            )}
                          </span>
                        </Link>
                      </li>
                    );
                  })}
              </ul>
              {props.chipsCount.length == 1 && <p>Please select your number 0-99</p>}
            </StyledEventTime>
          )}
        </StyledOverlayHead>
        {props.eventsData.length == 0 && (
          <>
          <StyledOverlayBody className='noeventview'>
            <NoEventIconSvg/>
            <h4>No Events Scheduled</h4>
            <p>
              There are no more events happening <br/>today (
              {moment().format('DD/MM/YY')})
            </p>
            <br/>
            <p>
              The next event will occur on:<br/> (
              <strong>{moment().add(1, 'days').format('DD/MM/YY')})</strong>
            </p>
            </StyledOverlayBody>
          </>
        )}
        {props.eventsData.length > 0 && (
          <StyledOverlayBody>
            {props.chipsCount.length > 1 && (
              <>
                {props.chipsCount.length > 1 && <p>Select 3 digits from 0-9</p>}
                <StyledGameAmountView>
                  <StyledGameAmount
                    className={`${enableChipError ? 'custom-error' : ''}`}
                  >
                    <StyledGameRow>
                      <StyledGameInputText>
                        <Link to=''>
                          <GameNumberOneIconSvg />
                          <p> {values[0] !== null ? values[0] : '-'}</p>
                        </Link>
                        <Link to=''>
                          <GameNumberTwoIconSvg />
                          <p> {values[1] !== null ? values[1] : '-'}</p>
                        </Link>
                        <Link to=''>
                          <GameNumberThreeIconSvg />
                          <p> {values[2] !== null ? values[2] : '-'}</p>
                        </Link>
                      </StyledGameInputText>
                    </StyledGameRow>
                    {enableChipError && (
                      <p className='custom-error'>
                        Please choose all three chips.
                      </p>
                    )}
                  </StyledGameAmount>
                </StyledGameAmountView>
                <ul className='numbersgame'>
                  {buttonNumberMap.length > 0 &&
                    buttonNumberMap.map((item: any, index: any) => {
                      return (
                        <li key={index}>
                          <Link
                            to={'#'}
                            onClick={() => handleNumberClick(item)}
                          >
                            {item}
                          </Link>
                        </li>
                      );
                    })}

                  <li className='removeback'>
                    <Link to={'#'} onClick={() => handleRemoveClick()}>
                      <NumberRemovebackIconSvg />
                    </Link>
                  </li>
                </ul>
                <Link
                  className='confirmbtn'
                  to={'#'}
                  onClick={() => confirmMultiChip()}
                >
                  Confirm
                </Link>
              </>
            )}
            {props.chipsCount.length == 1 && (
              <ul>
                {keyBoardCount.length > 0 &&
                  keyBoardCount.map((item: any, index: any) => {
                    return (
                      <li
                        key={index}
                        className={`${
                          chipActiveIndex === item ? 'active' : ''
                        }`}
                      >
                        <Link to={'#'} onClick={() => chooseChip(item)}>
                          {item}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            )}
          </StyledOverlayBody>
        )}
      </StyledOverlayContainer>
    </StyledAgeContainer>
  );
};

export default EventNumber;
