import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import themeReducer from '@/redux/slices/theme';
import { authenticationReducer } from '@/redux/slices/login';
import moduleReducer from '@/redux/slices/module';
import { settingReducer } from '@/redux/slices/setting';
import cartReducer from '@/redux/slices/cart';

export const store = configureStore({
  reducer: {
    theme: themeReducer,
    auth: authenticationReducer,
    module: moduleReducer,
    setting: settingReducer,
    cart: cartReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
