import React, { useState, useEffect, useMemo } from 'react';
import {
  StyledGameContainerFluid,
  StyledStraightBody,
  StyledEventTime,
  StyledGameAmountView,
  StyledGameAmount,
  StyledGameInfo,
  StyledGameInputText,
  StyledGameRow,
  StyledGameMainView,
  StyledEnterBetAmount,
  StyledBetAmountView,
  StyledCustomView,
  StyledQueueBtn,
  StyledPopupView,
  StyledGameInfopop,
} from './style';
import AuthService from '@/services/AuthService';
import { Link, useParams } from 'react-router-dom';
import {
  GameNumberOneIconSvg,
  GameNumberThreeIconSvg,
  GameNumberTwoIconSvg,
  HomeIconSvg,
  QueueIconSvg,
  SupportIconSvg,
  TicketsIconSvg,
} from '@/shared/svg';
import moment from 'moment';
import { setCartInfo, setCartTotal, setGameId } from '@/redux/slices/cart';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/hooks/useAppSelector';
import Loading from '@/components/Loading';
import EventNumber from '@/components/EventNumber';
import CustomAmount from '@/components/CustomAmount';
import Queue from '../queue/queue';
import { StyledFooterMain } from '@/layout/footer/style';

interface childProps {
  selectedEventData: any;
  selectedChip: any;
  eventsData: any;
  closeCustomAmountPopup: any;
  customAmount: any;
  closeQueuePopup: any;
  eventName: any;
  chipsCount: any;
}

interface queueHistory {
  entryType: 'sale';
  betAmount: any;
  unitBet: 5;
  totalAmount: any;
  runners: any;
  runnersDisplay: any;
  runnerQueue: any;
  betQuantity: 1;
  programId: any;
  poolName: any;
  action: 'submit';
  eventId: any;
  venueCode: any;
  groupCode: any;
  customerId: any;
  phoneNumber: any;
  sumTotal: any;
}

//var queueData: queueHistory[] = [];

const GameOn = (props: childProps) => {
  const dispatch = useDispatch();
  const [chipsCount, setChipsCount] = useState<any>([]);
  const [chipValue, setChipValue] = useState('--');
  const [betAmount, setBetAmount] = useState<any>('5');
  const [rumbleAmount, setRumbleAmount] = useState<any>('0');
  const [category, setCategory] = useState('');
  const [programId, setProgramId] = useState('');
  const [eventId, setEventId] = useState('');
  const [eventName, setEventName] = useState('');
  const [venueCode, setVenueCode] = useState('');
  const [eventsData, setEventsData] = useState([]);
  const [eventActiveIndex, setEventActiveIndex] = useState(0);
  const [eventNumberPopup, setEventNumberPopup] = useState(true);
  const [customAmountPopup, setCustomAmountPopup] = useState(false);
  const [enableQueue, setEnableQueue] = useState(false);
  const [queuedData, setQueuedData] = useState([]);
  const [betType, setBetType] = useState('single');
  const [paramValue, setParamValue] = useState('single');
  const [notifyTotalAmount, setNotifyTotalAmount] = useState(0);
  const [clearTicketsQueue, setClearTicketsQueue] = useState(false);

  const [loading, setLoading] = useState(false);
  const { cartInfo, cartTotal } = useAppSelector(state => state.cart);

  const handleChange = (e: { target: any }) => {
    setBetAmount(e.target.value);
  };

  const params = useParams();
  const getChipsandCategory = useMemo(() => {
    setParamValue(params.count);
    const num = Number(params.count);
    var N = num;
    var arrayCount = Array.apply(null, { length: N }).map(Number.call, Number);

    setChipsCount(arrayCount);
    setCategory(params.category);
  }, [params.count]);

  const selectProgramData = async (data: any, ind: number) => {
    if (queuedData.length > 0) {
      setClearTicketsQueue(true);
    }
    setProgramId(data.programId);
    setEventId(data.name.slice(5, 6));
    setEventName(data.startTime);
    setEventActiveIndex(ind);
  };
  const getEventsData = async () => {
    setLoading(true);
    const response = await AuthService.events(category);
    if (response.data != undefined && response.data.events.length > 0) {
      setLoading(false);
      setVenueCode(response.data.venueCode);
      setEventsData(response.data.events);
      selectProgramData(response.data.events[0], 0);
    } else {
      setLoading(false);
    }
  };

  const selectedEventData = async (item: any, ind: any) => {
    selectProgramData(item, ind);
  };
  const getSelectedChip = async (val: any) => {
    setEventNumberPopup(false);
    setChipValue(val);
  };

  const enableEventNumberPopup = async () => {
    setEventNumberPopup(true);
  };

  const enableCustomAmountPopup = async () => {
    setCustomAmountPopup(!customAmountPopup);
  };

  const closeCustomAmountPopup = async () => {
    setCustomAmountPopup(false);
  };

  const customAmount = async (val: any) => {
    if (betType == 'single') setBetAmount(val);
    else setRumbleAmount(val);
  };

  const enableQueuePopup = async () => {
    addToQueue();
    setEnableQueue(true);
  };

  const enableQueuePopup1 = async () => {
    setEnableQueue(true);
  };

  const closeQueuePopup = async () => {
    setEnableQueue(false);
  };

  const clearQueue = async () => {
    setQueuedData([]);
  };

  const selectBetType = async (val: any) => {
    setBetType(val);
    if (val == 'rumble') setRumbleAmount(30);
  };

  const getAmount = async (val: any) => {
    if (betType == 'single') setBetAmount(val);
    else setRumbleAmount(val);
  };

  function getPermutations(arr: any) {
    function backtrack(current: any) {
      if (current.length === arr.length) {
        permutations.push([...current]);
        return;
      }
      for (let i = 0; i < arr.length; i++) {
        if (!current.includes(arr[i])) {
          current.push(arr[i]);
          backtrack(current);
          current.pop();
        }
      }
    }

    const permutations: any[][] = [];
    backtrack([]);
    return permutations;
  }

  const addToQueue = async () => {
    if (chipValue == '--') return;
    if (chipValue == '---') return;
    let runnerForBackend: any;
    let displayTicket: any;
    let boxRunnersForBackend: any;
    let bAmount: any;
    let placeBetArr = [];
    if (category === 'WIN') {
      displayTicket = chipValue;
      runnerForBackend = parseInt(chipValue) + 1;
      bAmount = betAmount;
      placeBetArr.push({
        displayTicket: displayTicket,
        runnerForBackend: runnerForBackend,
        bAmount: bAmount,
        betAmount: betAmount,
        betType: 'Straight',
      });
    } else if (category === 'TAN') {
      if (chipsCount.length > 1) {
        if (rumbleAmount > 0) {
          
          // Example usage:
          const numbers = Array.from(String(chipValue));
          const permutations = getPermutations(numbers);
          console.log(permutations);

          bAmount = rumbleAmount;
          const num = chipValue;
          const arrOfStrs = Array.from(String(num));
          displayTicket = arrOfStrs.join(',');
          boxRunnersForBackend = displayTicket;
          displayTicket = displayTicket;
          const arrOfNums = arrOfStrs.map(str => Number(parseInt(str) + 1));
          const arrOfNums1 = arrOfNums.join(',');
          runnerForBackend = arrOfNums1 + '-' + arrOfNums1 + '-' + arrOfNums1;


          for (let i = 0; i < permutations.length; i++) {
            const innerArray = permutations[i];
            placeBetArr.push({
              displayTicket: permutations[i].join('-'),
              runnerForBackend: permutations[i].map((value) => parseFloat(value) + 1).join('-'),
              bAmount: bAmount,
              betAmount: rumbleAmount/permutations.length,
              betType: 'Rumble',
            });
  
          }

          // placeBetArr.push({
          //   displayTicket: displayTicket,
          //   runnerForBackend: runnerForBackend,
          //   bAmount: bAmount,
          //   betAmount: rumbleAmount,
          //   betType: 'Rumble',
          // });

        }

        if (betAmount > 0) {
          bAmount = betAmount;
          const num = chipValue;
          const arrOfStrs = Array.from(String(num));
          displayTicket = arrOfStrs.join('-');
          const arrOfNums = arrOfStrs.map(str => Number(parseInt(str) + 1));
          runnerForBackend = arrOfNums.join('-');
          placeBetArr.push({
            displayTicket: displayTicket,
            runnerForBackend: runnerForBackend,
            bAmount: bAmount,
            betAmount: betAmount,
            betType: 'Straight',
          });
        }
      }
    }

    setQueueRecord(placeBetArr);
  };

  const setQueueRecord = (placeBetArr: any) => {
    console.log(placeBetArr,'placeBetArr');
    const cartData = placeBetArr.map((placeBet: any) => {
      return {
        entryType: 'sale',
        betAmount: placeBet.betAmount,
        unitBet: 5,
        totalAmount: placeBet.bAmount,
        runners: placeBet.runnerForBackend,
        runnersDisplay: placeBet.runnerForBackend,
        runnerQueue: placeBet.displayTicket,
        betQuantity: 1,
        programId: programId,
        poolName: category,
        action: 'submit',
        eventId: eventId,
        venueCode: venueCode,
        groupCode: '',
        phoneNumber: '',
        sumTotal: 0,
        customerId: '',
        betType: placeBet.betType,
      };
    });
    let currentTotal = cartData.reduce(function (prev: number, current: any) {
      return prev + +current.totalAmount;
    }, 0);
    const totalCartValue = notifyTotalAmount + currentTotal;
    setNotifyTotalAmount(totalCartValue);
    setQueuedData([...queuedData, ...cartData]);
    setBetAmount(5);
    setRumbleAmount(0);
    if (paramValue == '1') {
      setChipValue('--');
    } else {
      setChipValue('---');
    }
  };

  const warningClose = async () => {
    setClearTicketsQueue(false);
  };

  const warningProceed = async () => {
    clearQueue();
    setClearTicketsQueue(false);
    dispatch(setCartInfo([]));
    dispatch(setCartTotal([]));
  };
  useEffect(() => {
    getEventsData();
    if (cartInfo) {
      setQueuedData(cartInfo);
      setNotifyTotalAmount(cartTotal);
    }
  }, []);

  useEffect(() => {
    // console.log(cartTotal);
    // setNotifyTotalAmount(cartTotal);
  }, [cartTotal]);

  useEffect(() => {}, [notifyTotalAmount]);

  const calculateCartTotal = () => {
    let currentTotal = queuedData.reduce(function (prev: number, current: any) {
      return prev + +current.betAmount;
    }, 0);
    setNotifyTotalAmount(currentTotal);
  };

  useEffect(() => {
    if (queuedData.length > 0) {
      dispatch(setCartInfo(queuedData));
      dispatch(setCartTotal(notifyTotalAmount));
      calculateCartTotal();
    }
  }, [queuedData]);

  return (
    <>
      {loading && <Loading />}
      {clearTicketsQueue && (
        <StyledPopupView>
          <StyledGameInfopop>
            <h4>Are you sure?</h4>
            <p>
              2.Your current number selection will be lost if you change the
              Event. Would you like to proceed?
            </p>
            <div className='buttonview'>
              <Link
                className='closebtn'
                to={'#'}
                onClick={() => warningClose()}
              >
                Cancel
              </Link>

              <Link
                className='confirmbtn'
                to={'#'}
                onClick={() => warningProceed()}
              >
                proceed
              </Link>
            </div>
          </StyledGameInfopop>
        </StyledPopupView>
      )}
      {eventNumberPopup && (
        <EventNumber
          selectedEventData={selectedEventData}
          selectedChip={getSelectedChip}
          eventsData={eventsData}
          chipsCount={chipsCount}
        />
      )}
      {customAmountPopup && (
        <CustomAmount
          customAmount={customAmount}
          closeCustomAmountPopup={closeCustomAmountPopup}
          selectedEventData={undefined}
          selectedChip={undefined}
          eventsData={undefined}
          betType={betType}
        />
      )}
      <StyledGameContainerFluid>
        <StyledGameMainView>
          <StyledEventTime>
            <p>Event</p>
            <ul>
              {eventsData &&
                eventsData.map((item: any, index: any) => {
                  return (
                    <li
                      key={index}
                      className={`${
                        eventActiveIndex === index ? 'active' : ''
                      }`}
                    >
                      <Link
                        to={'#'}
                        onClick={() => selectProgramData(item, index)}
                      >
                        {moment(item.startTime.slice(0, 2), 'HH').format('h')}
                        <span>
                          {moment(item.startTime.slice(0, 2), 'HH').format('a')}
                        </span>
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </StyledEventTime>
          <StyledGameInfo>
            {/* {chipsCount.length == '1' && <p>Please select your number 0 - 99</p>}
            {chipsCount.length > '1' && <p>Select 3 digits from 0-9</p>} */}
            {/* <Link to=''>
              <InfoGameSvg />
            </Link> */}
          </StyledGameInfo>
          <StyledGameAmountView>
            <StyledGameAmount>
              <StyledGameRow>
                <div
                  className='popevent'
                  onClick={() => enableEventNumberPopup()}
                >
                  {chipsCount.length == 1 && (
                    <StyledGameInputText>
                      <div className='textposition'>
                        <GameNumberOneIconSvg />
                        <p>{chipValue}</p>
                      </div>
                    </StyledGameInputText>
                  )}
                  {chipsCount.length > 1 && (
                    <StyledGameInputText>
                      <div className='double_digit textposition'>
                        <GameNumberOneIconSvg />
                        <p>{chipValue[0]}</p>
                      </div>
                      <div className='double_digit textposition'>
                        <GameNumberTwoIconSvg />
                        <p>{chipValue[1]}</p>
                      </div>
                      <div className='double_digit textposition'>
                        <GameNumberThreeIconSvg />
                        <p>{chipValue[2]}</p>
                      </div>
                    </StyledGameInputText>
                  )}
                  {chipsCount.length == 1 && (
                    <p className='edittext'>
                      {chipValue === '--'
                        ? 'Click to enter number'
                        : 'Click to edit number'}
                    </p>
                  )}
                  {chipsCount.length > 1 && (
                    <p className='edittext'>
                      {chipValue === '---'
                        ? 'Click to enter number'
                        : 'Click to edit number'}
                    </p>
                  )}
                </div>
              </StyledGameRow>
            </StyledGameAmount>
          </StyledGameAmountView>
          <StyledEnterBetAmount>
            <p>Enter bet amount</p>
            <StyledBetAmountView>
              {chipsCount.length == 1 && (
                <StyledStraightBody>
                  <div className='custominput'>
                    <span className='straighticon'>₱</span>
                    {betAmount}
                  </div>
                </StyledStraightBody>
              )}

              {chipsCount.length > 1 && (
                <StyledStraightBody>
                  <div className='straightview'>
                    <span
                      className={`${
                        betType === 'single' ? 'active' : 'disabled'
                      }`}
                    >
                      Straight
                    </span>
                    <div
                      className={`${
                        betType === 'single'
                          ? 'custominput active'
                          : 'custominput disabled'
                      }`}
                      onClick={() => selectBetType('single')}
                    >
                      <span className='straighticon'>₱</span>
                      {betAmount}
                    </div>
                  </div>
                  <div className='rumble'>
                    <span
                      className={`${
                        betType === 'rumble' ? 'active' : 'disabled'
                      }`}
                    >
                      Rumble
                    </span>
                    <div
                      className={`${
                        betType === 'rumble'
                          ? 'custominput active'
                          : 'custominput disabled'
                      }`}
                      onClick={() => selectBetType('rumble')}
                    >
                      <span className='straighticon'>₱</span>
                      {rumbleAmount}
                    </div>
                  </div>
                </StyledStraightBody>
              )}
              <StyledCustomView>
                <ul className={`${betType == 'single' ? '' : 'disabled'}`}>
                  <li>
                    <Link to={'#'} onClick={() => getAmount(5)}>
                      <span className='straighticon'>&#8369;</span>5
                    </Link>
                  </li>
                  <li>
                    <Link to={'#'} onClick={() => getAmount(10)}>
                      <span className='straighticon'>&#8369;</span>10
                    </Link>
                  </li>
                  <li>
                    <Link to={'#'} onClick={() => getAmount(15)}>
                      <span className='straighticon'>&#8369;</span>15
                    </Link>
                  </li>
                  <li>
                    <Link to={'#'} onClick={() => getAmount(20)}>
                      <span className='straighticon'>&#8369;</span>20
                    </Link>
                  </li>
                </ul>
              </StyledCustomView>
              <Link to={'#'} onClick={() => enableCustomAmountPopup()}>
                <p className='custombtn'>CUSTOM</p>
              </Link>
            </StyledBetAmountView>
            <StyledQueueBtn>
              <Link
                className={`${
                  chipValue == '--'
                    ? 'addqueuebtn disabled'
                    : chipValue == '---'
                    ? 'addqueuebtn disabled'
                    : 'addqueuebtn active'
                }`}
                to={'#'}
                onClick={() => addToQueue()}
              >
                Add to Queue
              </Link>
            </StyledQueueBtn>
          </StyledEnterBetAmount>
        </StyledGameMainView>
      </StyledGameContainerFluid>
      {enableQueue && (
        <Queue
          queueList={queuedData}
          closeQueuePopup={closeQueuePopup}
          clearQueue={clearQueue}
          eventName={eventName}
          setParentQueuedData={setQueuedData}
          setParentCartTotal={setNotifyTotalAmount}
        />
      )}
      <>
        <StyledFooterMain>
          <ul>
            <li>
              <Link to='/dashboard'>
                <HomeIconSvg />
                <p>Home</p>
              </Link>
            </li>
            <li
              className={`${
                enableQueue && queuedData.length > 0 ? 'active' : ''
              }`}
            >
              <Link to={'#'} onClick={() => enableQueuePopup1()}>
                <QueueIconSvg />
                {queuedData.length > 0 && (
                  <span className='notification'>
                    <div className='bets'>
                      <h4>
                        <b>{queuedData.length}</b> Bet(s)
                      </h4>
                    </div>
                    <div className='total'>
                      <span className='amounticon'>
                        <span>&#8369;</span> {notifyTotalAmount}
                      </span>
                    </div>
                  </span>
                )}
                <p>Queue</p>
              </Link>
            </li>
          </ul>
          <ul className='placebet'>
            {/* className={`${
                queuedData.length == 0
                  ? 'placebetview disabled'
                  : 'placebetview'
              }`} */}

            <li
              className={`${
                chipValue == '--' && queuedData.length == 0
                  ? 'placebetview disabled'
                  : chipValue == '---' && queuedData.length == 0
                  ? 'placebetview disabled'
                  : 'placebetview'
              }`}
            >
              <Link to={'#'} onClick={() => enableQueuePopup()}>
                <span className='placebetbutton'>
                  <p>PLACE</p>
                  <h6>BET</h6>
                </span>
              </Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to={'#'}>
                <TicketsIconSvg />
                <p>Tickets</p>
              </Link>
            </li>
            <li>
              <Link to='/support'>
                <SupportIconSvg />
                <p>Support</p>
              </Link>
            </li>
          </ul>
        </StyledFooterMain>

        {/* <Link to='/' >
          Home-
        </Link>
        <Link to={'#'} onClick={() => enableQueuePopup()}>
          Queue-
        </Link>
        <Link to={'#'} onClick={() => enableQueuePopup()}>
          Place Bet
        </Link>
        <Link to='/' >
          Tickets-
        </Link>
        <Link to='/' >
          Support
        </Link> */}
      </>
      {/* <Footer
        placeBet={undefined}
        enableQueue={undefined}
        queueList={undefined}
        enableQueuePopup={false}
      /> */}
    </>
  );
};

export default GameOn;
