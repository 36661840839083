import React, { useState, useEffect, useMemo } from 'react';
import {
  StyledQueueContainerFluid,
  StyledQueueContainer,
  StyledQueueListContainer,
  StyledQueueListHeader,
  StyledQueueListContent,
  StyledQueueListFooter,
  StyledDashboardHead,
  StyledQueueListView,
  StyledQueContainerView,
  StyledQueHead,
} from './style';
import { Link } from 'react-router-dom';
import {
  QueueCloseIconSvg,
  HeaderBackIconSvg,
  CloseIconSvg,
} from '@/shared/svg';
import {
  StyledGameContainerFluid,
  StyledGameInfopop,
  StyledGameMainView,
  StyledPopupView,
} from '../gameon/style';
import { ButtonSpinner, Input, Label } from '@/components';
import AuthService from '@/services/AuthService';
import Ticket from '../ticket/ticket';
import { setCartInfo, setCartTotal, setGameId } from '@/redux/slices/cart';
import { useDispatch } from 'react-redux';
import { setWalletData } from '@/redux/slices/login';
import Loading from '@/components/Loading';

interface childProps {
  queueList: any;
  closeQueuePopup: Function;
  clearQueue: Function;
  eventName: any;
  setParentQueuedData: Function;
  setParentCartTotal: Function;
}

const Queue = (props: childProps) => {
  const dispatch = useDispatch();
  const [queuedData, setQueuedData] = useState(props.queueList);
  const [totalAmountSum, setTotalAmountSum] = useState(0);
  const [enablePhonePopup, setEnablePhonePopup] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [enableBetSpinner, setEnableBetSpinner] = useState(false);
  const [success, setSuccess] = useState(false);
  const [enableTicketPopup, setEnableTicketPopup] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [groupCodeData, setGroupCodeData] = useState('');
  const [enableQueuePopup, setEnableQueuePopup] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [eventName, setEventName] = useState('');
  const [loading, setLoading] = useState(false);

  const getBettingCalculation = async () => {
    setSuccess(false);
    let totAmount = 0;
    queuedData.map((item: any, index: any) => {
      totAmount += parseFloat(item.betAmount);
      setTotalAmountSum(totAmount);
    });
    
  };

  const closeQueuePopup = () => {
    props.closeQueuePopup();
  };

  const removeQueue = async (removeIndex: number) => {
    const unfrozenObj = JSON.parse(JSON.stringify(queuedData));
    const removedQueData = unfrozenObj.filter(( place: any,index: number) => index !== removeIndex);
    let currentTotal = removedQueData.reduce(function(prev:number, current:any) {
      return prev + +current.betAmount
    }, 0);
   // removedQueData.splice(0,1);
    
    setQueuedData(removedQueData);
    props.setParentQueuedData(removedQueData);
    props.setParentCartTotal(currentTotal);
    dispatch(setCartInfo(removedQueData));
    dispatch(setCartTotal(currentTotal));
    setSuccess(false);
  };

  const submitBet = async () => {
    setEnablePhonePopup(true);
  };

  const handlePhoneChange = (e: { target: any }) => {
    setPhoneNumber(e.target.value);
  };

  const closePhoneNumber = () => {
    setEnablePhonePopup(false);
  };

  const confirmPlaceBet = async () => {
    // if (phoneNumber == '') {
    //   setPhoneNumberError(true);
    //   return;
    // }
    // if (phoneNumber != '' && phoneNumber.length != 10) {
    //   setPhoneNumberError(true);
    //   return;
    // }
    setEnableBetSpinner(true);
    setLoading(true);
    if (queuedData.length > 0) {
      let sumTotal = 0;
      const cart_data = queuedData;
      const unfrozenObj = JSON.parse(JSON.stringify(queuedData));
      const unfrozendata = unfrozenObj.map((item: any, index: any) => {
        sumTotal += parseInt(item.betAmount);
        item.phoneNumber = localStorage.getItem('Username');
        item.customerId = localStorage.getItem('Username');
        item.sumTotal = sumTotal;
        return item;
      });
      setPhoneNumber('');
      const response = await AuthService.submitBet(unfrozendata);
      if (response.status) {
        setLoading(false);
        setSuccess(true);
        setEnableTicketPopup(true);
        setEnablePhonePopup(false);
        setResponseData(queuedData);
        setGroupCodeData(response.data);
        setEnableQueuePopup(false);
        setEnableBetSpinner(false);
        setQueuedData([]);
        props.clearQueue();
        dispatch(setCartInfo([]));
        dispatch(setCartTotal([]));   
        const cardId = localStorage.getItem('cardId');
        const results = await AuthService.agentBalance(cardId);    
        dispatch(setWalletData(results.data)); 
      } else {
        setError(true);
        setLoading(false);
        setErrorMessage(response.data);
        setEnablePhonePopup(false);
        setEnableQueuePopup(false);
        setEnableBetSpinner(false);
      }
    }
  };

  const fetch = async (data: any) => {
    const response = await AuthService.submitBet(data);
    return response;
  };

  const closePopup = async () => {
    setEnableQueuePopup(false);
    setError(false);
    setErrorMessage('');
  };

  const closeTicketPopup = async () => {
    setEnableTicketPopup(false);
  };

  useEffect(() => {
    getBettingCalculation();
  }, [queuedData]);

  return (
    <>
      {loading && <Loading />}
      {enableTicketPopup && (
        <Ticket
          eventName={props.eventName}
          phoneNumer={phoneNumber}
          responseData={responseData}
          groupCodeData={groupCodeData}
          closeTicketPopup={closeTicketPopup}
          closeQueuePopup={closeQueuePopup}
        />
      )}
      {error && (
        <StyledPopupView>
          <StyledGameInfopop>
            <Link className='closeicon' to='' onClick={() => closePopup()}>
              <CloseIconSvg />
            </Link>
            <p>{errorMessage}</p>
          </StyledGameInfopop>
        </StyledPopupView>
      )}
      {enablePhonePopup && (
        <>
          <StyledPopupView>
            <StyledGameInfopop>
              <h4>
                Enter Player
                <br /> Phone Number
              </h4>
              {/* <p>Please enter Player phone number</p> */}
              <div className='loginform'>
                {/* <Label display='block' mb='5px' htmlFor='email'>
                  Phone Number
                </Label> */}
                <span className='phonecode'>+ 63</span>
                <Input
                  id='phoneNumber'
                  type='text'
                  placeholder='Enter 10 digit phone number'
                  name={'phoneNumber'}
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  maxlength={10}
                  className={`${
                    phoneNumberError ? 'straight error' : 'straight'
                  }`}
                />
                {phoneNumberError && (
                  <p className='error erroephonenumber'>
                    {' '}
                    Enter Phone number or Valid Number
                  </p>
                )}
                <div className='buttonview'>
                  <Link
                    className='closebtn'
                    to={'#'}
                    onClick={() => closePhoneNumber()}
                  >
                    Cancel
                  </Link>
                  <Link
                    className='confirmbtn'
                    to={'#'}
                    onClick={() => confirmPlaceBet()}
                  >
                    {enableBetSpinner ? <ButtonSpinner /> : null}Proceed
                  </Link>
                </div>
              </div>
            </StyledGameInfopop>
          </StyledPopupView>
        </>
      )}
      {!enableTicketPopup && !success && (
        <StyledQueueContainerFluid>
          <StyledQueContainerView>
            <StyledQueHead>
              <Link
                className='closeicon'
                to=''
                onClick={() => closeQueuePopup()}
              >
                <HeaderBackIconSvg />
              </Link>
              <h4>Queue</h4>
            </StyledQueHead>
            <StyledQueueContainer>
              <StyledQueueListContainer>
                <StyledQueueListHeader>
                  <strong className='comb_head'>
                    Combination{enableTicketPopup}
                  </strong>
                  <strong className='amount_head'>Amount{!success}</strong>
                </StyledQueueListHeader>
                <StyledQueueListView>
                  <StyledQueueListContent>
                    {queuedData.length > 0 &&
                      queuedData.map((item: any, index: any) => {
                        return (
                          <p>
                            <strong className='comb_head'>
                              {item.runnerQueue} 
                              <span className='bettype'>{item.betType}</span>
                            </strong>
                            <span className='amount_head'>
                              ₱ {item.betAmount}
                              <Link
                                className='closebtn'
                                to={'#'}
                                onClick={() => removeQueue(index)}
                              >
                                <QueueCloseIconSvg />
                              </Link>
                            </span>
                          </p>
                        );
                      })}

                    {queuedData.length == 0 && (
                      <p className='noqueue'>
                        No tickets found{enableTicketPopup}
                      </p>
                    )}
                  </StyledQueueListContent>
                  {queuedData.length > 0 && (
                    <StyledQueueListFooter>
                      <strong className='total_text'>Total</strong>
                      <strong className='total_amount'>
                        ₱ {totalAmountSum}
                      </strong>
                    </StyledQueueListFooter>
                  )}
                  {queuedData.length > 0 && (
                    <div className='confirmbtnview'>
                      <Link
                        className='confirmbtn'
                        to={'#'}
                        onClick={() => confirmPlaceBet()}
                      >
                        <p className='custombtn'>Confirm Bet</p>
                      </Link>
                    </div>
                  )}
                </StyledQueueListView>
              </StyledQueueListContainer>
            </StyledQueueContainer>
          </StyledQueContainerView>
        </StyledQueueContainerFluid>
      )}
    </>
  );
};

export default Queue;
