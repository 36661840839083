import * as React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from 'react-router-dom';

import Loading from './components/Loading';

import Layout from './layout';
import AuthService from './services/AuthService';
const Home = React.lazy(() => import('./pages/home/home'));
const Game = React.lazy(() => import('./pages/game/game'));
const Dashboard = React.lazy(() => import('./pages/dashboard/dashboard'));
const Ticket = React.lazy(() => import('./pages/ticket/ticket'));
const Queue = React.lazy(() => import('./pages/queue/queue'));
const Support = React.lazy(() => import('./pages/support/support'));
const AccessDenied = React.lazy(() => import('./pages/error-403'));

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { setIsAuthenticatedData, setUserData, setWalletData } from '@/redux/slices/login';
import CookieStorageService from './services/CookieStorageService';
import { PrivateRoute } from './privateRoute';
import { element } from 'prop-types';
import SigninForm from './pages/auth/signin';
import TicketsHistory from './pages/ticketshistory/ticketshistory';
import GameOn from './pages/gameon/gameon';

const App: React.FC = () => {
  React.useEffect(() => {}, []);

  return (
    <>
      <Router>
        <React.Suspense fallback={<Loading />}>
          <Routes>
            <Route
              element={
                <Layout hideFooter hideHeader>
                  <Outlet />
                </Layout>
              }
            >
              <Route path='/' element={<SigninForm />} />
            </Route>

            <Route
              element={
                <Layout hideFooter>
                  <Outlet />
                </Layout>
              }
            >
              <Route path='/accessdenied' element={<AccessDenied />} />
            </Route>

            <Route
              element={
                <Layout hideFooter hideHeader>
                  <Outlet />
                </Layout>
              }
            >
              <Route path='/login' element={<SigninForm />} />
            </Route>

            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }
            >
              <Route
                path='/game/:count/:category'
                element={<PrivateRoute component={Game} />}
              />
            </Route>
            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }
            >
              <Route
                path='/gameon/:count/:category'
                element={<PrivateRoute component={GameOn} />}
              />
            </Route>
            <Route
              element={
                <Layout hideFooter>
                  <Outlet />
                </Layout>
              }
            >
              <Route
                path='/dashboard'
                element={<PrivateRoute component={Dashboard} />}
              />
            </Route>

            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }
            >
              <Route
                path='/ticketshistory'
                element={<PrivateRoute component={TicketsHistory} />}
              />
            </Route>
            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }
            >
              <Route
                path='/ticket'
                element={<PrivateRoute component={Ticket} />}
              />
            </Route>

            <Route
              element={
                <Layout hideHeader>
                  <Outlet />
                </Layout>
              }
            >
              <Route
                path='/queue'
                element={<PrivateRoute component={Queue} />}
              />
            </Route>

            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }
            >
              <Route
                path='/support'
                element={<PrivateRoute component={Support} />}
              />
            </Route>
          </Routes>
        </React.Suspense>
      </Router>
    </>
  );
};

export default App;
