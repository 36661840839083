import React, { useEffect } from 'react';
import {
  StyledTicketContainerFluid,
  StyledTicketContainer,
  StyledTicketHeader,
  StyledTicketCombination,
  StyledTicketTransaction,
  StyledTicketMiddle,
  StyledDashboardHead,
} from './style';
import { useAppSelector } from '@/hooks/useAppSelector';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import { TransTickCircleSvg, HeaderBackIconSvg } from '@/shared/svg';
import QRCode from 'react-qr-code';
import Footer from '@/layout/footer';

interface childProps {
}

const TicketsHistory = (props: childProps) => {
  const { userInfo } = useAppSelector(state => state.auth);

  useEffect(() => {
    //getBettingHistoryData();
  }, []);

  return (
    <>
      <StyledTicketContainerFluid>
        <StyledTicketContainer>
          <StyledTicketHeader>
            <ul>
              <li>
                <span>Event Details:</span>
                <strong>{moment().format('ha')}</strong>
              </li>
              <li>
                <span>Ticket Date:</span>
                <strong>{moment().format('lll')}</strong>
              </li>
              <li>
                <span>Contact Number:</span>
                <strong>7799900021</strong>
              </li>
            </ul>
          </StyledTicketHeader>
          <StyledTicketCombination>
            <ul>
              <li className='header_row'>
                <span className='header_col'>Combination</span>
                <strong className='header_col'>Straight</strong>
              </li>
              <li>
                <span>9</span>
                <strong>₱ 200</strong>
              </li>
            </ul>
          </StyledTicketCombination>
          <StyledTicketMiddle>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </StyledTicketMiddle>
          <StyledTicketTransaction>
            <h3>TICKET NO. SHAFIAHAMED</h3>
            <div className='qrcode_info'>
              <QRCode
                style={{ height: '160', maxWidth: '100%', width: '160' }}
                value={'SHAFIAHAMED'}
                viewBox={`0 0 160 160`}
              />
            </div>
            <ul>
              <li>
                <span>Total Transactions</span>
                <strong>1</strong>
              </li>
              <li>
                <span>Total Amount</span>
                <strong>₱ 200</strong>
              </li>
            </ul>
            <p>
              <TransTickCircleSvg />
              <span>Ticket Confirmation</span>
            </p>
            <p>
              <TransTickCircleSvg />
              <span>SMS Confirmation</span>
            </p>
          </StyledTicketTransaction>
        </StyledTicketContainer>
      </StyledTicketContainerFluid>
      <Footer
        placeBet={undefined}
        enableQueue={undefined}
        queueList={undefined}
        enableQueuePopup={undefined}
      />
    </>
  );
};

export default TicketsHistory;
