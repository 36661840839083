import { createSlice } from '@reduxjs/toolkit';

const initialState: IAuthentication = {
  userInfo: undefined,
  walletInfo: undefined,
  isAuthenticated: false,
  queueEmpty:0,
  isLoading: false,
  isSuccess: undefined,
  isError: undefined,
  errorMessage: undefined,
};

const authenticationSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetUser: state => {
      Object.assign(state, initialState);
    },
    setQueueEmpty: (state) => {
      state.queueEmpty += 1;
    },
    setUserData(state, action) {
      state.userInfo = action.payload;
    },
    setWalletData(state, action) {
      state.walletInfo = action.payload;
    },
    setIsAuthenticatedData(state, action) {
      if (action.payload != undefined) state.isAuthenticated = action.payload;
    },
  },
});
export const {
  setUserData,
  setQueueEmpty,
  setIsAuthenticatedData,
  setWalletData,
  resetUser
} = authenticationSlice.actions;
export const authenticationReducer = authenticationSlice.reducer;
